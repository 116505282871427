import { P3 } from '@missionlane/compass-ui';
import { DescriptionList } from '../../../General/DescriptionList/DescriptionList';
import { RewardsCategories } from './RewardsCategories';
import { AccountDetailsPageQuery } from '@core/graphql/globalTypes';

const REWARDS_LABEL = 'Cash back rewards';

interface RewardsDetailsProps {
  rewards: AccountDetailsPageQuery['rewards'];
}

const RewardsDetails = ({ rewards }: RewardsDetailsProps) => {
  if (rewards) {
    const { isRewardsCard, categoryRewardsRate, rewardsRate, categoryName } =
      rewards;

    // if it's not a rewards card, or we don't know the rewards rate, render nothing
    if (!isRewardsCard || !rewardsRate) {
      return null;
    }

    // rewards card with category-specific rewards
    if (categoryName && categoryRewardsRate) {
      return (
        <DescriptionList.Item
          label={REWARDS_LABEL}
          subTitle={
            <RewardsCategories
              categoryName={categoryName}
              rewardsRate={rewardsRate}
              categoryRewardsRate={categoryRewardsRate}
            />
          }
        />
      );
    }

    // rewards card without category-specific rewards
    return <DescriptionList.Item label={REWARDS_LABEL} value={rewardsRate} />;
  }

  return (
    <DescriptionList.Item
      label={REWARDS_LABEL}
      subTitle={
        <P3 color="mustard">We can't load your rewards info right now</P3>
      }
    />
  );
};

export default RewardsDetails;
