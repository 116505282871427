import { H4, Notification } from '@missionlane/compass-ui';
import { useEffect } from 'react';

import { PaymentMethodName } from '../Pay/Pay';
import PaymentDisplay from './PaymentDisplay';
import { PaymentsSuccessButtons } from './PaymentsSuccessButtons';
import { FundingAccounts } from '@core/graphql/globalTypes';
import { useTracking } from '@core/services/TrackService/useTracking';

interface PaymentSuccessContentProps {
  amount: number | undefined;
  className?: string;
  confirmationNumber: string | null | undefined;
  fundingAccount?: FundingAccounts.FundingAccounts | null;
  isImmediate?: boolean;
  isPaymentSuccess?: boolean;
  paymentDate?: string | null;
  paymentMethod?: PaymentMethodName;
}

const PaymentSuccessContent = ({
  amount,
  confirmationNumber,
  fundingAccount,
  isImmediate,
  paymentDate,
  paymentMethod,
}: PaymentSuccessContentProps) => {
  const { trackEvent } = useTracking();

  useEffect(() => {
    trackEvent({
      eventName: 'Payment Success',
      name: 'Payment Success',
      feature: 'Make Payment',
      metadata: {
        fundingAccount,
        isImmediate,
        paymentMethod,
        selectAmount: amount,
        selectDate: paymentDate,
      },
    });
  }, []);

  return (
    <>
      <div className="ph3 ph0-l">
        <H4>You're all set!</H4>
        <PaymentDisplay
          amount={amount}
          className="mv5"
          fundingAccount={fundingAccount}
          isImmediate={isImmediate}
          isPaymentSuccess={true}
          paymentDate={paymentDate}
          paymentMethod={paymentMethod}
        />
        <Notification level="success">
          Thanks for your payment! We've sent you an email which includes your
          confirmation number {confirmationNumber} and payment details.
        </Notification>
        <PaymentsSuccessButtons
          isDebitCardPayment={paymentMethod === PaymentMethodName.DEBIT_CARD}
        />
      </div>
    </>
  );
};

export default PaymentSuccessContent;
