import currency from 'currency.js';
import dayjs from 'dayjs';
import { ClipOfferStatusCodes } from '@clip/ClipProgress/hooks/types/ClipOffer';
import useClipOfferV2 from '@clip/ClipProgress/hooks/useClipOfferV2';

const ClipOfferKardTitle = ({ children }: { children: React.ReactNode }) => (
  <h4 style={{ fontSize: 20 }} className="mb3">
    {children}
  </h4>
);

const InProgressTitle = () => {
  const clipOffer = useClipOfferV2();

  if (clipOffer?.isInGracePeriod) {
    return (
      <ClipOfferKardTitle>
        {clipOffer?.userFirstName}, make a payment now to stay on time!
      </ClipOfferKardTitle>
    );
  }

  return (
    <ClipOfferKardTitle>
      You could boost your credit limit as soon as{' '}
      <span className="green">
        {dayjs(clipOffer?.evaluationDate).format('MMMM')}.
      </span>
    </ClipOfferKardTitle>
  );
};

const EvaluationPendingTitle = () => {
  const clipOffer = useClipOfferV2();

  return (
    <ClipOfferKardTitle>
      {clipOffer?.userFirstName}, great job making on time payments!
    </ClipOfferKardTitle>
  );
};

const ApprovedTitle = () => {
  const clipOffer = useClipOfferV2();

  if (!clipOffer?.currentCreditLimit) return null;

  return (
    <ClipOfferKardTitle>
      Congrats! You just boosted your credit limit to{' '}
      <span className="green">
        {currency(clipOffer?.currentCreditLimit, { precision: 0 }).format()}.
      </span>
    </ClipOfferKardTitle>
  );
};

const OverlimitTitle = () => (
  <ClipOfferKardTitle>
    You still have a chance at a credit limit increase!
  </ClipOfferKardTitle>
);

const IneligibleTitle = () => (
  <ClipOfferKardTitle>
    We can't increase your credit limit right now.
  </ClipOfferKardTitle>
);

const MissedPaymentTitle = () => (
  <ClipOfferKardTitle>
    We can't increase your credit limit right now.
  </ClipOfferKardTitle>
);

const TITLE_CONTENT: Record<ClipOfferStatusCodes, () => JSX.Element | null> = {
  [ClipOfferStatusCodes.IN_PROGRESS]: InProgressTitle,
  [ClipOfferStatusCodes.EVALUATION_PENDING]: EvaluationPendingTitle,
  [ClipOfferStatusCodes.APPROVED]: ApprovedTitle,
  [ClipOfferStatusCodes.INELIGIBLE]: IneligibleTitle,
  [ClipOfferStatusCodes.MISSED_PAYMENT]: MissedPaymentTitle,
  [ClipOfferStatusCodes.OVERLIMIT]: OverlimitTitle,
  [ClipOfferStatusCodes.PENDING_PAYMENT]: InProgressTitle,
} as const;

const Title = () => {
  const clipOffer = useClipOfferV2();

  const Content =
    clipOffer?.clipStatus &&
    TITLE_CONTENT[clipOffer.clipStatus as ClipOfferStatusCodes];

  if (!Content) {
    return null;
  }

  return <Content />;
};

export default Title;
