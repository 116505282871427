import cx from 'classnames';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Button, H3, H2, P3, Spacer } from '@missionlane/compass-ui';
import { useCallback } from 'react';
import {
  initialPaymentValues,
  initialRemainingMinDueValues,
  useMakePayment,
} from './MakePaymentContext';
import PageWrapper from '@core/components/Page/PageWrapper';
import schedulePaymentCalendarSVG from '@core/assets/schedulePaymentCalendar.svg';
import { useAccountSummaryPath } from '@core/utils/hooks/useAccountSummaryPath';
import { useTracking } from '@core/services/TrackService/useTracking';
import { usePaymentStatus } from '@payments/hooks/usePaymentStatus';
import { centsToDollars } from '@core/utils/centsToDollars';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';

const MinDuePrompt = () => {
  const navigate = useNavigate();
  const summaryPath = useAccountSummaryPath();
  const { trackClick } = useTracking();
  const { isTachyonsNotSmall } = useUserDevice();
  const {
    paymentValues: { amount },
    updatePaymentValues,
    updateRemainingMinDueValues,
  } = useMakePayment();
  const { printDueDate, minimumDue } = usePaymentStatus();

  const HeaderComponent = isTachyonsNotSmall ? H3 : H2;

  // if (minimumDue == undefined || amount == undefined) return <LoadingPage />;

  const minDueCopy =
    minimumDue !== undefined && amount !== undefined ?
      `${centsToDollars(minimumDue - amount)} `
    : '';

  const optimisticMinDueAmount =
    minimumDue !== undefined && amount !== undefined ? minimumDue - amount : 0;

  const onSchedule = useCallback(() => {
    updatePaymentValues(initialPaymentValues);
    updateRemainingMinDueValues({
      ...initialRemainingMinDueValues,
      isMinDueSchedulePrompt: true,
      optimisticMinDueAmount,
    });
    trackClick({
      name: 'Schedule Min Due Button',
      feature: 'Payments: Make Payment',
    });
    navigate('../');
  }, [optimisticMinDueAmount]);

  const onSkip = () => {
    trackClick({
      name: 'Skip Min Due Button',
      feature: 'Payments: Make Payment',
    });
    navigate(summaryPath);
  };

  return (
    <PageWrapper
      isSingleColumn
      trackingProperties={{
        pageName: 'Minimum Due Prompt Page',
        featureName: 'Payments: Make Payment',
      }}
    >
      <div
        className={cx('flex flex-column', {
          'items-center': !isTachyonsNotSmall,
        })}
      >
        <img
          src={schedulePaymentCalendarSVG}
          alt="calendar with dollar sign"
          width={150}
        />

        <Spacer size="s" />
        <HeaderComponent>
          <div
            className={cx('', {
              tc: !isTachyonsNotSmall,
            })}
          >
            {`Schedule your remaining ${minDueCopy}payment?`}
          </div>
        </HeaderComponent>
        <Spacer size="m" />

        <P3>
          {`Schedule it now or pay by ${dayjs(printDueDate).format(
            'MMM D',
          )} to avoid a late fee.`}
        </P3>
        <Spacer size="m" />
      </div>
      <div
        className={cx('flex', {
          'flex-column justify-center': !isTachyonsNotSmall,
        })}
      >
        <Button onPress={onSchedule} text={`Schedule ${minDueCopy}payment`} />
        <Spacer size="m" />
        <Button onPress={onSkip} variant="text" text="Skip" />
      </div>
    </PageWrapper>
  );
};

export default MinDuePrompt;
