import { useNavigate, useLocation } from 'react-router-dom';
import { B, Link, Notification } from '@missionlane/compass-ui';
import { useState } from 'react';
import { NotificationLevel } from '@missionlane/compass-ui/lib/typescript/components/Notification/types';
import dayjs from 'dayjs';
import { SETTLEMENTS_FEATURE_NAME } from '../../ChargedOff/utils/events';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import {
  AccountSummaryPageQuery,
  OfferAggregate,
  SettlementOfferState,
  SettlementOfferSubtype,
} from '@core/graphql/globalTypes';
import { ACCOUNT_SUMMARY_PAGE_QUERY } from '@core/components/AccountSummaryPage/AccountSummaryPage';
import { useSettlementOffers } from '@core/components/ChargedOff/utils/useSettlementOffers';
import { useReturnedPaymentExperience } from '@core/components/ChargedOff/utils/useReturnedPaymentExperience';
import { TrackService } from '@core/services';
import isNil from '@core/utils/isNil';

const ChargedOffBanner = () => {
  const [showBanner, setShowBanner] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const {
    activeOffer,
    currentBalance,
    isInKatabat,
    isInDSCProgram,
    isInLitigation,
    loading: loadingOffers,
    error,
  } = useSettlementOffers({ fetchPolicy: 'cache-and-network' });

  const { data: accountData, loading: loadingAccount } =
    useAccountIdQuery<AccountSummaryPageQuery>(ACCOUNT_SUMMARY_PAGE_QUERY);
  const isSummaryPage = location?.pathname?.includes('summary');
  const isChargedOff = accountData?.account?.statuses?.isChargedOff;
  const isBankrupt = accountData?.account?.statuses?.isBankrupt;
  const { showReturnedPaymentExperience, loading: offerPaymentsLoading } =
    useReturnedPaymentExperience(activeOffer);

  if (
    loadingAccount ||
    loadingOffers ||
    offerPaymentsLoading ||
    !isChargedOff ||
    isBankrupt ||
    (activeOffer?.data.state === SettlementOfferState.InProgress &&
      !showReturnedPaymentExperience) ||
    !isSummaryPage ||
    isInKatabat ||
    isInDSCProgram ||
    error ||
    isNil(currentBalance)
  ) {
    return null;
  }

  const bannerContent = getChargedOffBannerContent(
    activeOffer,
    currentBalance,
    showReturnedPaymentExperience,
    isInLitigation,
  );

  return (
    <Notification
      show={showBanner}
      level={bannerContent.level}
      variant="banner"
      onDismiss={() => setShowBanner(false)}
    >
      <B>{bannerContent.textPrimary}</B>
      {bannerContent.textSecondary && (
        <span>&nbsp;{bannerContent.textSecondary}</span>
      )}
      {bannerContent.textEndBold && <B>&nbsp;{bannerContent.textEndBold}</B>}
      {bannerContent.linkText && (
        <>
          &nbsp;
          <Link
            onPress={() =>
              navigate('../payment-plan/faq', {
                state: { faqVariant: bannerContent.variant },
              })
            }
          >
            {bannerContent.linkText}
          </Link>
        </>
      )}
    </Notification>
  );
};

export enum ChargedOffBannerVariant {
  FULFILLED_PIF = 'FULFILLED_PIF',
  FULFILLED_SIF = 'FULFILLED_PIF',
  PENDING_CLOSEOUT = 'PENDING_CLOSEOUT',
  RETURNED_PAYMENT = 'RETURNED_PAYMENT',
  IN_LITIGATION = 'IN_LITIGATION',
  DEFAULT = 'DEFAULT',
}

type ChargedOffBanner = {
  variant: ChargedOffBannerVariant;
  level: NotificationLevel;
  textPrimary: string;
  textSecondary?: string;
  textEndBold?: string;
  linkText?: string;
};

const getChargedOffBannerContent = (
  activeOffer: OfferAggregate | null | undefined,
  currentBalance: number,
  hasReturnedPayment: boolean,
  isInLitigation: boolean | undefined,
): ChargedOffBanner => {
  if (isInLitigation) {
    TrackService.trackEvent('Charged Off Dashboard - Is In Litigation', {
      feature: SETTLEMENTS_FEATURE_NAME,
    });
    return {
      variant: ChargedOffBannerVariant.IN_LITIGATION,
      level: 'error',
      textPrimary:
        'Your account is charged off and can’t be used. Your account has been placed with TrakAmerica. They can be reached at (855) 400-3889.',
    };
  }
  if (hasReturnedPayment && activeOffer) {
    return {
      variant: ChargedOffBannerVariant.RETURNED_PAYMENT,
      level: 'error',
      textPrimary: 'You have a returned payment.',
      textSecondary:
        'You can add or edit payments anytime to reach your plan total',
      textEndBold: `by ${dayjs(activeOffer.data.expiration).format(
        'MMMM DD',
      )}.`,
    };
  }
  if (activeOffer?.data.state === SettlementOfferState.Fulfilled) {
    if (
      activeOffer.data.subType === SettlementOfferSubtype.Pif ||
      currentBalance <= 0
    ) {
      return {
        variant: ChargedOffBannerVariant.FULFILLED_PIF,
        level: 'info',
        textPrimary: 'Your account is charged off and can’t be used.',
        linkText: 'What does this mean?',
      };
    }
    return {
      variant: ChargedOffBannerVariant.FULFILLED_SIF,
      level: 'warning',
      textPrimary:
        'Your account is settled, but you can still make a payment to reach paid in full status.',
    };
  }
  if (activeOffer?.data.state === SettlementOfferState.PendingCloseOut) {
    TrackService.trackEvent(
      'Charged Off Dashboard - Offer Pending Close Out Banner',
      {
        feature: SETTLEMENTS_FEATURE_NAME,
      },
    );
    return {
      variant: ChargedOffBannerVariant.PENDING_CLOSEOUT,
      level: 'info',
      textPrimary:
        'Your final payment is pending. It can take up to two weeks to apply to your balance.',
    };
  }
  return {
    variant: ChargedOffBannerVariant.DEFAULT,
    level: 'error',
    textPrimary:
      'Your account is charged off and can’t be used. Please make a payment or create a plan.',
    linkText: 'Learn more here.',
  };
};

export default ChargedOffBanner;
