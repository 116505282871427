import { Button } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import { TrackService } from '@core/services';
import { ClipOfferStatusCodes } from '@clip/ClipProgress/hooks/types/ClipOffer';
import useClipOfferV2 from '@clip/ClipProgress/hooks/useClipOfferV2';

const StreakFooter = ({ children }: { children: React.ReactNode }) => (
  <p className="mt0 mb0">{children}</p>
);

const Footer = () => {
  const clipOffer = useClipOfferV2();
  const navigate = useNavigate();

  if (clipOffer?.clipStatus === ClipOfferStatusCodes.MISSED_PAYMENT) {
    return null;
  }

  if (clipOffer?.isInGracePeriod) {
    return (
      <StreakFooter>
        <Button
          onPress={() => {
            TrackService.click(
              'Clip Tracker Relaunch Control: Is in grace period: Click make a payment',
              {
                experience: clipOffer?.experience,
              },
            );
            navigate('../payments/pay');
          }}
          text="Make a Payment"
        />
      </StreakFooter>
    );
  }

  return null;
};

export default Footer;
