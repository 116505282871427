import { useQuery, gql } from '@apollo/client';
import { ColorNames, Link, P3 } from '@missionlane/compass-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'react-router-dom';

import { useAccount } from '@core/components/Auth/AccountContext';
import { DescriptionList } from '@core/components/General/DescriptionList/DescriptionList';
import {
  GetAccountCreditProtectionDetailsQueryQueryVariables,
  GetAccountCreditProtectionDetailsQueryQuery,
  InsuranceStatus,
} from '@core/graphql/globalTypes';
import { useTracking } from '@core/services/TrackService/useTracking';
import { MLFlags } from 'flags';

// exported for testing purposes
export const valueCopyMap: Record<InsuranceStatus, string> = {
  [InsuranceStatus.Insured]: 'Enrolled',
  [InsuranceStatus.NotInsured]: 'Enroll',
  [InsuranceStatus.Pending]: 'Pending',
  [InsuranceStatus.ManualPending]: 'Pending',
  [InsuranceStatus.Vendor]: 'Unknown',
  [InsuranceStatus.Cancelled]: 'Cancelled',
  [InsuranceStatus.Enrolled]: 'Enrolled',
  [InsuranceStatus.Suspended]: 'Suspended',
  [InsuranceStatus.NotEnrolled]: 'Enroll',
  [InsuranceStatus.NotDetermined]: 'Unknown',
};

// exported for testing purposes
export const valueCopyColor: Record<InsuranceStatus, ColorNames> = {
  [InsuranceStatus.Insured]: 'green',
  [InsuranceStatus.NotInsured]: 'blue',
  [InsuranceStatus.Pending]: 'inkLight',
  [InsuranceStatus.ManualPending]: 'inkLight',
  [InsuranceStatus.Vendor]: 'inkLight',
  [InsuranceStatus.Cancelled]: 'red',
  [InsuranceStatus.Enrolled]: 'green',
  [InsuranceStatus.Suspended]: 'red',
  [InsuranceStatus.NotEnrolled]: 'blue',
  [InsuranceStatus.NotDetermined]: 'inkLight',
};

const getUserCanEnroll = (insuranceStatus?: InsuranceStatus | null) =>
  insuranceStatus === InsuranceStatus.NotInsured ||
  insuranceStatus === InsuranceStatus.NotEnrolled;

const getUserCanSSO = (insuranceStatus?: InsuranceStatus | null) =>
  insuranceStatus === InsuranceStatus.Enrolled ||
  insuranceStatus === InsuranceStatus.Pending ||
  insuranceStatus === InsuranceStatus.Suspended ||
  insuranceStatus === InsuranceStatus.Cancelled;

// exported for testing purposes
export const getSublabelCopy = (insuranceStatus: InsuranceStatus) => {
  if (getUserCanEnroll(insuranceStatus)) {
    return 'Get help with your card account payments if you experience certain financial hardships.';
  }

  return 'Call 1-888-276-6443 to manage enrollment or initiate a benefit.';
};

//exported for testing purposes
export const SSOLinkText = 'Manage enrollment or initiate a benefit';

export const GET_ACCOUNT_CREDIT_PROTECTION_DETAILS_QUERY = gql`
  query GetAccountCreditProtectionDetailsQuery($accountId: String!) {
    account(accountId: $accountId) {
      creditProtectionInfo {
        status
        isEligible
      }
    }
  }
`;

const CreditProtection = () => {
  const { creditProtection, creditProtectionMarketing, creditProtectionSso } =
    useFlags<MLFlags>();

  const { trackClick } = useTracking();
  const { accountId } = useAccount();
  const navigate = useNavigate();

  const { data } = useQuery<
    GetAccountCreditProtectionDetailsQueryQuery,
    GetAccountCreditProtectionDetailsQueryQueryVariables
  >(GET_ACCOUNT_CREDIT_PROTECTION_DETAILS_QUERY, {
    skip: !creditProtection || !accountId,
    variables: { accountId },
  });

  const creditProtectionStatus = data?.account?.creditProtectionInfo?.status;
  const userIsEligible = data?.account?.creditProtectionInfo?.isEligible;
  const userCanEnroll = getUserCanEnroll(creditProtectionStatus);
  const userCanSSO = getUserCanSSO(creditProtectionStatus);

  const handlePressManageEnrollment = () => {
    const redirectLink = `${process.env.REACT_APP_CREDIT_PROTECTION_URL}?RelayState=${accountId}`;

    window.location.href = redirectLink;
  };

  if (!creditProtection || !creditProtectionStatus) {
    return null;
  }

  // handles all the cases where the user can't enroll (mostly for frontbook users)
  if (!userCanEnroll) {
    return (
      <div data-testid="credit-protection-enrolled">
        <DescriptionList.Item
          label="Credit Protection"
          subTitle={
            creditProtectionSso && userCanSSO ?
              <Link onPress={handlePressManageEnrollment}>{SSOLinkText}</Link>
            : <P3 color="inkLight">
                {getSublabelCopy(creditProtectionStatus)}
              </P3>
          }
          value={
            <P3 color={valueCopyColor[creditProtectionStatus]}>
              {valueCopyMap[creditProtectionStatus]}
            </P3>
          }
        />
      </div>
    );
  }

  // do not show the enrollment option if the flag is disabled, or the user is not eligible
  if (creditProtectionMarketing === 'DISABLED' || !userIsEligible) return null;

  const handlePressEnroll = () => {
    if (userCanEnroll && userIsEligible) {
      navigate('../credit-protection');

      trackClick({
        name: 'Enroll',
        feature: 'CreditProtectionProfileSection',
      });
    }
  };

  // only elegible backbook clients will see the enroll link
  return (
    <div data-testid="credit-protection-not-enrolled">
      <DescriptionList.Item
        label="Credit Protection"
        subTitle={
          <P3 color="inkLight">{getSublabelCopy(creditProtectionStatus)}</P3>
        }
        value={
          <Link onPress={handlePressEnroll}>
            {valueCopyMap[creditProtectionStatus]}
          </Link>
        }
      />
    </div>
  );
};

export default CreditProtection;
