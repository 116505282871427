import useClipOfferV2 from '@clip/ClipProgress/hooks/useClipOfferV2';

type GetStreakTitle = {
  currentPaymentStreak: number;
  userFirstName: string;
  isInGracePeriod: boolean;
};

const getStreakTitle = ({
  currentPaymentStreak,
  userFirstName,
  isInGracePeriod,
}: GetStreakTitle) => {
  if (isInGracePeriod) {
    return `${userFirstName}, make a payment now to stay on track!`;
  }

  switch (currentPaymentStreak) {
    case 0:
      return "Let's get a streak going!";
    case 1:
      return `${userFirstName}, you've started a streak!`;
    default:
      return `${userFirstName}, way to keep making on time payments!`;
  }
};

function Title() {
  const clipOffer = useClipOfferV2();

  const title = getStreakTitle({
    currentPaymentStreak: clipOffer?.currentPaymentStreak ?? 0,
    userFirstName: clipOffer?.userFirstName ?? '',
    isInGracePeriod: !!clipOffer?.isInGracePeriod,
  });

  return <h5>{title}</h5>;
}

export default Title;
