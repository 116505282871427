import { Colors, Icon } from '@missionlane/compass-ui';
import cx from 'classnames';
import { FC, useState } from 'react';
import { useSpringCarousel } from 'react-spring-carousel';

import { TileDismissedAt } from '../OpportunityTileCarousel/helpers';
import { TrackServiceEventProperties } from '@core/services/TrackService/TrackService';

// event name defined by react-spring-carousel but not exported
const ON_SLIDE_START_CHANGE_EVENT = 'onSlideStartChange';

export type OnDismiss = (
  dismissedAt: TileDismissedAt,
  options?: {
    skipLocalStorageUpdate?: boolean;
    trackingProperties?: TrackServiceEventProperties;
    eventName?: string;
  },
) => void;

export interface CarouselProps {
  data: CarouselTile[];
  onDismiss: OnDismiss;
  carouselFragmentClassnames?: string;
}

export interface CarouselTileProps {
  body: string;
  ctaText: string;
  id: string;
  isVisible: boolean;
  onDismiss: OnDismiss;
  title: string;
}

export type CarouselTile = {
  component: FC<CarouselTileProps>;
} & Pick<CarouselTileProps, 'body' | 'ctaText' | 'id' | 'title'>;

const Carousel = ({
  data,
  onDismiss,
  carouselFragmentClassnames,
}: CarouselProps) => {
  const [activeTileIndex, setActiveTileIndex] = useState(0);

  const renderTile = (tileProps: CarouselTile, index: number) => {
    // Very unlikely that this would happen, but just in case
    if (!tileProps.component) return null;

    const Component = tileProps.component;

    // TODO: EEC-183 - add copy props here
    return (
      <div className="flex justify-center items-center w-100">
        <Component
          body={tileProps.body}
          ctaText={tileProps.ctaText}
          id={tileProps.id}
          isVisible={activeTileIndex === index}
          title={tileProps.title}
          onDismiss={onDismiss}
        />
      </div>
    );
  };

  const hasMoreThanOneTile = data.length > 1;

  const {
    useListenToCustomEvent,
    carouselFragment,
    slideToNextItem,
    slideToPrevItem,
    thumbsFragment,
    slideToItem,
  } = useSpringCarousel({
    withThumbs: hasMoreThanOneTile || undefined,
    withLoop: hasMoreThanOneTile || undefined,
    disableGestures: !hasMoreThanOneTile,
    enableThumbsWrapperScroll: false,
    gutter: 8,
    items: data.map((item, index) => {
      return {
        id: item.id,
        renderItem: renderTile(item, index),
        renderThumb: (
          <div
            style={{
              backgroundColor: `${activeTileIndex == index ? Colors.blue : Colors.inkLightest}`,
              borderRadius: '50%',
              marginRight: '4px',
              marginLeft: '4px',
              height: '7px',
              width: '7px',
            }}
            onClick={() => slideToItem(item.id)}
          />
        ),
      };
    }),
  });

  useListenToCustomEvent((event) => {
    if (event.eventName === ON_SLIDE_START_CHANGE_EVENT) {
      setActiveTileIndex(event.nextItem.index);
    }
  });

  if (!data?.length) return null;

  return (
    <>
      <div
        className={cx('flex flex-row items-center w-100', {
          'justify-between': hasMoreThanOneTile,
          'justify-center': !hasMoreThanOneTile,
        })}
      >
        {hasMoreThanOneTile && (
          <div className="db-ns dn pointer mr1" onClick={slideToPrevItem}>
            <Icon color="blue" name="back" />
          </div>
        )}
        <div className={cx('overflow-hidden', carouselFragmentClassnames)}>
          {carouselFragment}
        </div>
        {hasMoreThanOneTile && (
          <div className="db-ns dn pointer ml1" onClick={slideToNextItem}>
            <Icon color="blue" name="forward" />
          </div>
        )}
      </div>
      {hasMoreThanOneTile && (
        <div className="flex items-center justify-center mt3 ">
          <div>{thumbsFragment}</div>
        </div>
      )}
    </>
  );
};

export default Carousel;
