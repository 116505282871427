import { MLError } from '..';
import TrackService, {
  TrackServiceEventProperties,
  TrackServiceNotifiableError,
} from './TrackService';
import { useCustomer } from '@core/components/App/CustomerContext';
import { useAccount } from '@core/components/Auth/AccountContext';

export interface TrackingProperties
  extends Pick<
    TrackServiceEventProperties,
    'feature' | 'error' | 'testProperties' | 'metadata'
  > {
  name: string;
  [key: string]: unknown;
}

export interface ErrorEventTrackingProperties
  extends Pick<TrackServiceEventProperties, 'name' | 'feature' | 'metadata'> {
  error: TrackServiceNotifiableError;
}

export interface EventTrackingProperties
  extends Pick<TrackingProperties, 'feature' | 'error' | 'metadata'> {
  eventName: string;
  name?: TrackingProperties['name'];
  variant?: TrackingProperties['variant'];
}

export const useTracking = () => {
  const { accountId } = useAccount();
  const { id: customerId } = useCustomer();

  const user = {
    ecsCustomerId: customerId,
    ecsAccountId: accountId,
  };

  return {
    /** Tracks Page View event to Segment and FullStory */
    trackPage: ({ name, feature, error, metadata }: TrackingProperties) => {
      TrackService.trackPage(name, {
        feature,
        user,
        error,
        metadata,
      });
    },
    /** Tracks error event to Segment, FullStory and MLError */
    trackError: ({
      name,
      feature,
      error,
      metadata,
    }: ErrorEventTrackingProperties) => {
      TrackService.trackError(name, { feature, user, error, metadata });

      MLError.report({
        error,
        prefix: error.code,
        name,
      });
    },
    /** Tracks click event to Segment and FullStory */
    trackClick: ({
      name,
      feature,
      error,
      testProperties,
      metadata,
    }: TrackingProperties) =>
      TrackService.trackClick(name, {
        feature,
        user,
        error,
        testProperties,
        metadata,
      }),
    /** Tracks generic event to Segment and FullStory */
    trackEvent: ({
      eventName,
      name,
      feature,
      variant,
      error,
      metadata,
    }: EventTrackingProperties) =>
      TrackService.trackEvent(eventName, {
        name,
        feature,
        variant,
        user,
        error,
        metadata,
      }),
  };
};
