import { Link } from '@missionlane/compass-ui';
import { useState } from 'react';
import OfferDetailsModal from '@clip/ClipProgress/components/OfferDetailsModal/OfferDetailsModal';
import { ClipOfferStatusCodes } from '@clip/ClipProgress/hooks/types/ClipOffer';
import useClipOfferV2 from '@clip/ClipProgress/hooks/useClipOfferV2';

const SeeOfferDetailsLink = () => {
  const clipOffer = useClipOfferV2();
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  if (
    clipOffer?.clipStatus === ClipOfferStatusCodes.APPROVED ||
    clipOffer?.clipStatus === ClipOfferStatusCodes.OVERLIMIT ||
    clipOffer?.clipStatus === ClipOfferStatusCodes.INELIGIBLE ||
    clipOffer?.clipStatus === ClipOfferStatusCodes.MISSED_PAYMENT ||
    clipOffer?.isInGracePeriod
  ) {
    return null;
  }

  return (
    <>
      <Link
        style={{
          textDecorationLine: 'none',
          fontWeight: 'bold',
        }}
        onPress={toggleModal}
      >
        More Details
      </Link>
      <OfferDetailsModal isOpen={modalOpen} onClose={toggleModal} />
    </>
  );
};

export default SeeOfferDetailsLink;
