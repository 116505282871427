import { P3 } from '@missionlane/compass-ui';
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import creditProtectionCardAndShield from '@core/assets/CreditProtectionCardAndShield.svg';
import { CREDIT_PROTECTION_QUERY } from '@core/components/CreditProtection/creditProtectionQuery';
import Modal from '@core/components/General/Modal/Modal';
import { CreditProtectionQuery } from '@core/graphql/globalTypes';
import { useTracking } from '@core/services/TrackService/useTracking';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import { MLFlags } from 'flags';

export const CREDIT_PROTECTION_MODAL_EVENT = 'credit-protection-modal';

export const CREDIT_PROTECTION_MODAL_DISMISSED_AT =
  'credit-protection-modal-dismissed-at';

const CreditProtectionModal = () => {
  const { creditProtectionMarketing } = useFlags<MLFlags>();
  const { trackClick, trackEvent } = useTracking();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState<boolean>(
    !localStorage.getItem(CREDIT_PROTECTION_MODAL_DISMISSED_AT),
  );

  const isFlagDisabled = creditProtectionMarketing === 'DISABLED';

  const { data } = useAccountIdQuery<CreditProtectionQuery>(
    CREDIT_PROTECTION_QUERY,
    { skip: isFlagDisabled },
  );

  const showCreditProtectionModal =
    !isFlagDisabled &&
    data?.account?.creditProtectionInfo?.isEligibleForMarketing;

  const onClose = () => {
    trackClick({
      feature: CREDIT_PROTECTION_MODAL_EVENT,
      name: 'Close',
      variant: 'MODAL_TILE_C',
    });

    setIsOpen(false);

    localStorage.setItem(
      CREDIT_PROTECTION_MODAL_DISMISSED_AT,
      dayjs().toString(),
    );
  };

  const onLearnMore = () => {
    trackClick({
      feature: CREDIT_PROTECTION_MODAL_EVENT,
      name: 'Learn More',
      variant: 'MODAL_TILE_C',
    });

    navigate('../credit-protection');
  };

  useEffect(() => {
    if (showCreditProtectionModal && isOpen) {
      trackEvent({
        eventName: 'Modal Viewed',
        feature: CREDIT_PROTECTION_MODAL_EVENT,
        variant: 'MODAL_TILE_C',
      });
    }
  }, [showCreditProtectionModal, isOpen]);

  if (!showCreditProtectionModal) {
    return null;
  }

  return (
    <Modal
      headerText="Introducing Mission Lane Credit Protection"
      isOpen={isOpen}
      maxWidth="640px"
      primaryButton={{
        onPress: onLearnMore,
        text: 'Learn More',
      }}
      secondaryButton={{
        onPress: onClose,
        text: 'Close',
      }}
      onClose={onClose}
    >
      <div>
        <div
          className="flex items-center justify-center br2 mb4 mt4"
          style={{ height: '184px' }}
        >
          <img className="h-75" src={creditProtectionCardAndShield} />
        </div>
        <P3>
          Enrolling in Mission Lane Credit Protection can help with your card
          account payments if you experience certain financial hardships,
          including job loss, auto repairs, hospitalization, and medical bills.
        </P3>
      </div>
    </Modal>
  );
};

export default CreditProtectionModal;
