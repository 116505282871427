import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, H4, P3, Spacing } from '@missionlane/compass-ui';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { gql, useMutation } from '@apollo/client';
import currency from 'currency.js';
import { HookFormTextField } from '../Form';
import { useAccount } from '../Auth/AccountContext';
import {
  RedeemRewardsMutation,
  RedeemRewardsMutationVariables,
} from '../../graphql/globalTypes';
import { useTracking } from '../../services/TrackService/useTracking';
import { centsToDollars } from '../../utils/centsToDollars';
import { getRewards, RewardsPageProps } from './RewardsPage';
import { RedemptionBanner } from './RedemptionBanner';
import { REWARDS_REDEMPTION_FEATURE_NAME } from './const';
import { MLError } from '@core/services';
import { isErrorResult } from '@core/utils/isErrorResult';

const redeemRewards = gql`
  mutation RedeemRewards($accountId: String!, $amount: Int!) {
    redeemRewards(accountId: $accountId, amount: $amount) {
      ... on ErrorResult {
        error {
          message
          statusCode
        }
      }
      ... on RedeemRewardsSuccess {
        accountId
        amount
      }
    }
  }
`;

type RewardsRedemptionFormValues = {
  amount: number;
};

export type RewardsRedemptionBanners = {
  success?: boolean;
  errorMessage?: string;
};

interface RewardsRedemptionFormProps
  extends RewardsRedemptionFormValues,
    RewardsPageProps {
  hasMarginTop?: boolean;
  isLoadingRefetch?: boolean;
}

const RewardsRedemptionForm = ({
  amount,
  hasMarginTop,
  setBanners,
  isLoadingRefetch,
}: RewardsRedemptionFormProps) => {
  const { accountId } = useAccount();
  const { trackClick } = useTracking();
  const initialRedemptionAmount = currency(amount, {
    fromCents: true,
  }).value;
  const validationSchema = Yup.object().shape({
    amount: Yup.string()
      .required('Please input an amount')
      .test(
        'amount',
        `You can’t redeem more than $${initialRedemptionAmount.toFixed(2)}`,
        (input) => (input ? currency(input).intValue <= amount : true),
      ),
  });

  const methods = useForm<RewardsRedemptionFormValues>({
    defaultValues: {
      amount: initialRedemptionAmount,
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const [remainingAmount, setRemainingAmount] = useState(currency(0).format());
  const { formState, watch, reset, handleSubmit } = methods;
  const { isValid, errors } = formState;

  const redemptionAmount = watch('amount');

  const [redeemRewardsMutation, { loading }] = useMutation<
    RedeemRewardsMutation,
    RedeemRewardsMutationVariables
  >(redeemRewards, {
    refetchQueries: [getRewards],
    variables: {
      accountId,
      amount: currency(redemptionAmount).intValue,
    },
  });

  useEffect(() => {
    if (formState.isSubmitSuccessful && !isLoadingRefetch) {
      reset();
    }
  }, [formState.isSubmitSuccessful, reset, isLoadingRefetch]);

  const handleInputTransform = (value: string) => {
    const valueAsNumber = Number(value.replace(/[.,]/g, ''));
    if (!isNaN(valueAsNumber) && valueAsNumber > 0) {
      return centsToDollars(valueAsNumber, { symbol: '' });
    }
    return '';
  };

  const onSubmit = async () => {
    trackClick({
      name: `${REWARDS_REDEMPTION_FEATURE_NAME}: Submit Rewards Redemption`,
      feature: REWARDS_REDEMPTION_FEATURE_NAME,
    });
    try {
      const { data } = await redeemRewardsMutation();
      const redeemRewardsData = data?.redeemRewards;

      if (redeemRewardsData) {
        if (isErrorResult(redeemRewardsData)) {
          setBanners([() => <RedemptionBanner isSuccess={false} />]);
          throw new Error(`${redeemRewardsData.error}`);
        } else {
          setBanners([() => <RedemptionBanner isSuccess />]);
          setRemainingAmount(currency(0).format());
        }
      } else {
        throw new Error('An unexpected error occurred');
      }
    } catch (error) {
      if (error instanceof Error)
        MLError.report(
          { name: error.name, error },
          {
            tags: { feature: REWARDS_REDEMPTION_FEATURE_NAME },
          },
        );
    }
  };

  const handleOnBlur = () => {
    setRemainingAmount(
      currency(amount, { fromCents: true })
        .subtract(currency(redemptionAmount).intValue)
        .format({ negativePattern: '!0.00' }),
    );
  };

  // Hide the form when there is no amount to redeem
  if (amount <= 0) return null;

  return (
    <div style={{ marginTop: hasMarginTop ? Spacing.xm : undefined }}>
      <H4>Redeem your Cash Back</H4>
      <P3>We’ll apply it to your balance.</P3>
      <FormProvider {...methods}>
        <div style={{ marginBottom: Spacing.xm, marginTop: Spacing.xm }}>
          <HookFormTextField
            icon="dollarSign"
            name="amount"
            onBlur={handleOnBlur}
            placeholder="0.00"
            error={errors?.amount?.message}
            helpText={`${remainingAmount} remaining`}
            transformInput={handleInputTransform}
            autoFocus
          />
        </div>
        <Button
          loading={loading}
          disabled={Number(redemptionAmount) * 100 == 0 || !isValid}
          text={`Redeem ${redemptionAmount && isValid ? centsToDollars(Number(redemptionAmount) * 100) : ''}`}
          onPress={handleSubmit(onSubmit)}
        />
      </FormProvider>
    </div>
  );
};

export default RewardsRedemptionForm;
