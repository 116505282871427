import cx from 'classnames';
import { useQuery, gql } from '@apollo/client';
import { H2 } from '@missionlane/compass-ui';
import { Button } from '@missionlane/compass-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { HeaderLogo } from '../../Page/HeaderLogo';
import { activeAccountIdVar, resetActiveAccountId } from '../NavContainer';
import { IconLink, NavLink } from './NavLink';
import { NavSectionHeader } from './NavSectionHeader';
import { SIDEBAR_NAVIGATION_FEATURE_ID } from './constants';
import { NavCardArt } from './NavCardArt';
import {
  GetCustomerNameQuery,
  SettlementOfferState,
  SettlementOfferSubtype,
  SidebarNavQuery,
  SidebarNavQueryVariables,
} from '@core/graphql/globalTypes';
import { GET_CUSTOMER_NAME_QUERY } from '@payments/components/Autopay/queries';
import { MLError } from '@core/services';
import { activeSettlementOfferStatuses } from '@core/components/ChargedOff/utils';
import { OfferType } from '@core/components/ChargedOff/utils/OfferType';
import { OfferIneligibilityReason } from '@core/components/ChargedOff/utils/types';
import isNil from '@core/utils/isNil';
import { MLFlags } from 'flags';

const SIDEBAR_NAV_QUERY = gql`
  query SidebarNav($accountId: String!) {
    account(accountId: $accountId) {
      id
      balanceInfo {
        currentBalance
      }
      statuses {
        isChargedOff
        isBankrupt
      }
    }
    creditCards {
      id
      name
      cardLast4
      cardArtUrl
    }
    customer {
      id
      hasPendingAccount
    }
    offers(accountId: $accountId) {
      offerId
      offerType
      data {
        state
        subType
      }
    }
    referralOffers(accountId: $accountId) {
      data {
        offerId
      }
    }
    offerEligibility(accountId: $accountId) {
      offerType
      eligible
      ineligibleReasons
    }
    rewards(accountId: $accountId) {
      accountId
      isRewardsCard
    }
  }
`;

interface NavProps {
  isMobile: boolean;
  isMobileOpen: boolean;
  onMobileClose: () => void;
}

const Nav = ({ isMobile, isMobileOpen, onMobileClose }: NavProps) => {
  const activeAccountId = activeAccountIdVar();
  const {
    manageFundingAccounts,
    referralsMarketing,
    enableRewardsRedemption,
    enableMyProgress,
    enableMyProgressOnboarding,
  } = useFlags<MLFlags>();
  const {
    data: sidebarNavQueryData,
    loading: sidebarNavQueryLoading,
    error: sidebarNavQueryError,
  } = useQuery<SidebarNavQuery, SidebarNavQueryVariables>(SIDEBAR_NAV_QUERY, {
    errorPolicy: 'all',
    skip: !activeAccountId,
    variables: {
      accountId: activeAccountId || '',
    },
  });
  const {
    creditCards = [],
    customer,
    offerEligibility,
    offers,
    account,
    referralOffers,
    rewards,
  } = sidebarNavQueryData || {};
  const { hasPendingAccount } = customer || {};
  const currentBalance = account?.balanceInfo.currentBalance;
  const activeSettlementOffer = offers?.find(
    (offer) =>
      offer.offerType === OfferType.Settlement &&
      activeSettlementOfferStatuses.includes(offer.data.state),
  );
  const hasInProgressOffer =
    activeSettlementOffer &&
    Boolean(
      activeSettlementOffer.data.state === SettlementOfferState.InProgress ||
        activeSettlementOffer.data.state ===
          SettlementOfferState.PendingCloseOut,
    );
  const hasFulfilledPifOffer =
    activeSettlementOffer &&
    !isNil(currentBalance) &&
    Boolean(
      activeSettlementOffer.data.state === SettlementOfferState.Fulfilled &&
        (activeSettlementOffer.data.subType === SettlementOfferSubtype.Pif ||
          currentBalance <= 0),
    );
  const settlementOfferEligibility = offerEligibility?.find(
    (offer) => offer.offerType === OfferType.Settlement,
  );
  const isIneligibleForSettlementOffer =
    settlementOfferEligibility && !settlementOfferEligibility.eligible;
  const isInDSCProgram =
    isIneligibleForSettlementOffer &&
    settlementOfferEligibility.ineligibleReasons.includes(
      OfferIneligibilityReason.IN_DSC_PROGRAM,
    );
  const isInKatabat =
    isIneligibleForSettlementOffer &&
    settlementOfferEligibility.ineligibleReasons.includes(
      OfferIneligibilityReason.IN_KATABAT,
    );
  const isMulticard =
    creditCards.length ? creditCards.length > 1 || hasPendingAccount : false;
  const showMulticardHomeNavLink = !isMobile && isMulticard;
  const activeAccountDetails =
    activeAccountId && creditCards.find((cc) => cc.id === activeAccountId);
  const { cardArtUrl, cardLast4, name: cardName } = activeAccountDetails || {};
  const showRewardsNavLink = enableRewardsRedemption && rewards?.isRewardsCard;

  const { data: customerNameQueryData } = useQuery<GetCustomerNameQuery>(
    GET_CUSTOMER_NAME_QUERY,
  );
  const customerFirstName =
    customerNameQueryData?.customer?.contactInfo.firstName;
  const isChargedOff = sidebarNavQueryData?.account?.statuses.isChargedOff;
  const isBankrupt = sidebarNavQueryData?.account?.statuses.isBankrupt;

  const hasExistingThirdPartySettlementPlan =
    isChargedOff && (isInDSCProgram || isInKatabat);
  const hasReferralsOffer =
    (referralOffers && referralOffers.length > 0) || false;

  const paymentsNavItemLinkTo =
    hasInProgressOffer ?
      `/account/${activeAccountId}/payment-plan`
    : `/account/${activeAccountId}/payments`;
  const bankAccountsNavItemLinkTo =
    hasInProgressOffer ?
      `/account/${activeAccountId}/edit-offer-funding-account`
    : manageFundingAccounts ? `/account/${activeAccountId}/manage-bank-accounts`
    : `/account/${activeAccountId}/add-bank-account`;
  const handleMobileClose = isMobile ? onMobileClose : undefined;

  // If we failed to fetch the customer's accounts we shouldn't display anything
  if (sidebarNavQueryError && !creditCards.length) {
    const errorMessage = 'SidebarNav: Failed to fetch customer accounts';
    MLError.report({ name: errorMessage });
    throw new Error(errorMessage);
  }

  return (
    <>
      {isMobile && isMobileOpen && (
        <div
          id="overlay"
          className="fixed bg-grey opacity-80 top-0 left-0 bottom-0 right-0 z-max"
          onClick={onMobileClose}
        />
      )}
      <div
        data-testid={SIDEBAR_NAVIGATION_FEATURE_ID}
        className={cx(
          'w5 flex flex-column',
          {
            'bg-compass-green pt5 pb4': !isMobile,
            'vh-100 fixed top-0 right-0 transition-transform bg-white z-max pv3 overflow-y-scroll':
              isMobile,
          },
          isMobile && (isMobileOpen ? 'translate-x-0' : 'translate-x-100'),
        )}
      >
        <div className={cx('pb3', isMobile ? 'ph3' : 'ph4')}>
          {isMobile ?
            <MobileNavHeader
              customerFirstName={customerFirstName}
              onClose={onMobileClose}
            />
          : <HeaderLogo color="white" />}
        </div>
        {showMulticardHomeNavLink && (
          <NavLink
            to="/"
            text="All cards"
            icon="back"
            trackingName="Multicard home"
          />
        )}
        {!isMobile && cardArtUrl && (
          <NavCardArt
            classNames={showMulticardHomeNavLink ? 'pt3' : undefined}
            cardArtUrl={cardArtUrl}
            cardName={cardName}
            cardLast4={cardLast4}
          />
        )}
        <NavLink
          to={`/account/${activeAccountId}/summary`}
          text="Home"
          icon="homeOutline"
          onClick={handleMobileClose}
          trackingName="Account summary"
        />
        {!sidebarNavQueryLoading &&
          !hasExistingThirdPartySettlementPlan &&
          !hasFulfilledPifOffer &&
          !isBankrupt && (
            <NavLink
              to={paymentsNavItemLinkTo}
              text="Payments"
              icon="dollarSign"
              onClick={handleMobileClose}
              trackingName="Payments overview"
            />
          )}
        {(enableMyProgressOnboarding || enableMyProgress) && (
          <NavLink
            to={`/account/${activeAccountId}/my-progress`}
            text="My Progress"
            icon="chart"
            onClick={handleMobileClose}
            trackingName="My Progress"
          />
        )}
        <NavLink
          to={`/account/${activeAccountId}/transactions`}
          text="Transactions"
          icon="transaction"
          onClick={handleMobileClose}
          trackingName="Transactions"
        />
        <NavLink
          to={`/account/${activeAccountId}/statements`}
          text="Statements"
          icon="document"
          onClick={handleMobileClose}
          trackingName="Statements"
        />
        {showRewardsNavLink && (
          <NavLink
            to={`/account/${activeAccountId}/rewards`}
            text="Rewards"
            icon="crown"
            onClick={handleMobileClose}
            trackingName="Rewards"
          />
        )}
        <NavLink
          to={`/account/${activeAccountId}/details`}
          text="Account Details"
          icon="infoCircleLight"
          onClick={handleMobileClose}
          trackingName="Account details"
        />
        <NavLink
          to={`/account/${activeAccountId}/manage-card`}
          text="Manage card"
          icon="creditCard"
          onClick={handleMobileClose}
          trackingName="Manage card"
        />
        <NavSectionHeader text="Your Profile" />
        <NavLink
          to="/my-profile"
          text="Profile"
          icon="user"
          onClick={handleMobileClose}
          trackingName="My profile"
        />
        {!sidebarNavQueryLoading && !isBankrupt && (
          <NavLink
            to={bankAccountsNavItemLinkTo}
            text="Bank accounts"
            icon="bank"
            onClick={handleMobileClose}
            trackingName={
              manageFundingAccounts ?
                'Manage bank accounts'
              : 'Add bank account'
            }
          />
        )}
        {referralsMarketing !== 'DISABLED' && hasReferralsOffer && (
          <NavLink
            to={`/account/${activeAccountId}/referrals`}
            text="Refer a friend"
            icon="bullhorn"
            onClick={handleMobileClose}
            trackingName="Refer a friend"
          />
        )}
        <NavSectionHeader text="Support" />
        <IconLink
          text="Help center"
          icon="circleQuestion"
          onClick={() => {
            handleMobileClose?.();
            window.open(process.env.REACT_APP_ZENDESK_URL, '_blank');
          }}
          trackingName="Help Center FAQ"
        />
        <IconLink
          text="Send message"
          icon="message"
          onClick={() => {
            handleMobileClose?.();
            window.open(
              `${process.env.REACT_APP_ZENDESK_URL}/hc/en-us/requests`,
              '_blank',
            );
          }}
          trackingName="View Messages"
        />
        <NavLink
          className={cx(isMobile ? 'mt2 mb7' : 'mt5')}
          to="/signout"
          text="Sign out"
          color={isMobile ? 'blue' : undefined}
          onClick={resetActiveAccountId}
          trackingName="Sign out"
        />
      </div>
    </>
  );
};

interface MobileNavHeaderProps {
  customerFirstName: string | undefined;
  onClose: () => void;
}

const MobileNavHeader = ({
  customerFirstName,
  onClose,
}: MobileNavHeaderProps) => (
  <div className="flex justify-between items-center">
    <div className="flex-auto">
      {customerFirstName && <H2>Hi, {customerFirstName}</H2>}
    </div>
    <Button
      accessibilityLabel="Close side navigation"
      variant="icon"
      icon="close"
      size="small"
      // @ts-ignore - TODO: Update compass to support custom icon button colors
      color="ink"
      onPress={onClose}
    />
  </div>
);

export default Nav;
