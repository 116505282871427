import * as React from 'react';
import * as Sentry from '@sentry/react';
import {
  Routes as ReactRoutes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { serializeError } from 'serialize-error';
import { fullStoryIntegration } from '@sentry/fullstory';
import { FullStory } from '@fullstory/browser';

import type {
  CaptureExceptionArgs,
  NotifiableError,
  SentryContext,
} from './types';
import { CustomerRoutesQuery } from '@core/graphql/globalTypes';
// const gqlEndpoint = process.env.REACT_APP_APOLLO_URL ?? ""

export const init = () => {
  Sentry.init({
    dsn: 'https://be6e392a4856e78a6afa2c2908b08ad5@o543490.ingest.us.sentry.io/4507017296871424',
    environment:
      window.location.host === 'dashboard.missionlane-staging.com' ?
        'staging'
      : process.env.NODE_ENV,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: true, // TODO: turning this on during the demo to prevent PII from being displayed
        blockAllMedia: true, // TODO: turning this on during the demo to prevent PII from being displayed
      }),
      fullStoryIntegration('mission-lane', {
        client: FullStory,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", gqlEndpoint], // TODO: CORS Errors from GQL?
  });

  // Configure tags
  Sentry.setTags({
    'commit-id': process.env.REACT_APP_GIT_COMMIT ?? 'development',
  });
};

export const setUser = (
  customer: NonNullable<CustomerRoutesQuery['customer']>,
) => {
  const email = customer.contactInfo.email || 'Customer email is not defined';
  Sentry.setUser({ id: customer.id, email });
};

/**
 *
 * @param error - logged into MLError message
 * @param name - identification by MLError
 * @param prefix - string in front in name that allows MLError to identify more specific errors
 * @returns NotifiableError - object wih name and a message containing a json stringified serialized error
 */
export const captureException = ({
  error,
  name,
  prefix,
  scopeContext,
}: CaptureExceptionArgs): NotifiableError => {
  let reportedError = error;
  const prefixedName = `${prefix ? `${prefix} - ` : ''}${name}`;

  let extraContext: SentryContext['extra'] = {
    name: prefixedName,
  };

  if (!(error instanceof Error)) {
    let formattedMessage: string;
    if (error === undefined) {
      formattedMessage = prefixedName;
    } else {
      formattedMessage = JSON.stringify(serializeError(error), null, 2);
      extraContext = {
        ...error,
        ...extraContext,
      };
    }
    reportedError = new Error(formattedMessage);
    extraContext['message'] = formattedMessage;
  }

  const notifiableError: NotifiableError = {
    name: extraContext.name,
    message:
      extraContext.message ||
      error?.message ||
      'No message provided with error',
  };

  const captureContext: SentryContext = {
    ...scopeContext,
    extra: extraContext,
  };

  Sentry.captureException(reportedError, captureContext);
  return notifiableError;
};

export const addBreadcrumb = Sentry.addBreadcrumb;

export const ErrorBoundary = Sentry.ErrorBoundary;

export const Routes = Sentry.withSentryReactRouterV6Routing(ReactRoutes);

export type { Metadata, ScopeContext } from './types';

export const setTags = Sentry.setTags;
