import {
  GetCreditCardsSectionDataQuery,
  HomePage as HomePageQuery,
} from '@core/graphql/globalTypes';
import isNil from '@core/utils/isNil';

export const checkIfSomeCreditCardsAreHydrated = (
  creditCards: GetCreditCardsSectionDataQuery['creditCards'],
) =>
  creditCards.some(
    (cc) => !isNil(cc.currentBalance) && !isNil(cc.availableCredit),
  );
