import { B, Notification } from '@missionlane/compass-ui';
import { useEffect, useState } from 'react';
import { REWARDS_REDEMPTION_FEATURE_NAME } from './const';
import { useTracking } from '@core/services/TrackService/useTracking';

interface RedemptionBannerProps {
  isSuccess: boolean;
}

export const RedemptionBanner = ({ isSuccess }: RedemptionBannerProps) => {
  const [dismissed, setDismissed] = useState(false);
  const { trackEvent, trackClick } = useTracking();

  useEffect(() => {
    trackEvent({
      eventName: `Banner Viewed`,
      name: `${REWARDS_REDEMPTION_FEATURE_NAME}: ${isSuccess ? 'Success' : 'Error'}`,
      feature: REWARDS_REDEMPTION_FEATURE_NAME,
    });
  }, []);

  const headline =
    isSuccess ?
      <>
        <B>Redeemed! </B>
        Your balance will update in 2-3 business days.
      </>
    : <>Error redeeming rewards</>;

  return (
    <Notification
      level={isSuccess ? 'success' : 'error'}
      show={!dismissed}
      variant="banner"
      onDismiss={() => {
        trackClick({
          name: `${REWARDS_REDEMPTION_FEATURE_NAME}: Banner dismissed`,
          feature: REWARDS_REDEMPTION_FEATURE_NAME,
        });
        setDismissed(true);
      }}
    >
      {headline}
    </Notification>
  );
};
