import { P3, B, Link, Notification } from '@missionlane/compass-ui';
import { centsToDollars } from '@core/utils/centsToDollars';
import { getUTC } from '@core/utils/timezones';

interface PastDueBodyProps {
  message: string;
  pastDueBalance: number | undefined;
  hasPastDueBalancePayment?: boolean;
}

const BucketsBody = ({
  message,
  pastDueBalance,
  hasPastDueBalancePayment,
}: PastDueBodyProps) => {
  const moreInfoUrl =
    'https://support.missionlane.com/hc/en-us/articles/13754717385875-Past-Due-Payment';

  return (
    <div className="mb4">
      <div className="flex items-end justify-between mb4">
        <div className="flex">
          <P3 style={styles.label}>Past Due Amount</P3>
        </div>
        <div className="flex">
          <P3 color="red" style={styles.label}>
            <B>{centsToDollars(pastDueBalance || 0)}</B>
          </P3>
        </div>
      </div>
      {!hasPastDueBalancePayment && (
        <Notification variant="inline" level="error">
          {message}&nbsp;
          <Link onPress={() => window.open(moreInfoUrl)}>Get more info</Link>
        </Notification>
      )}
    </div>
  );
};

export default BucketsBody;

const styles = {
  label: {
    margin: 0,
  },
};
