import { ApolloProvider } from '@apollo/client';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import apolloClient from '@core/client/apolloClient';
import App from '@core/components/App/App';
import { LD_CONSTANTS } from '@core/components/App/types/LaunchDarkly';
import { OktaSecurityProvider } from '@core/components/Auth/OktaSecurityProvider';
import { RiskAuthProvider } from '@core/components/Auth/RiskAuthProvider';
import { ScrollToTop } from '@core/components/General/ScrollToTop';
import { GenericFallbackFullPage } from '@core/components/GenericFallbacks/GenericFallbackFull';
import { ModalProvider } from '@core/contexts/modal';
import { MLError, RiskAuth } from '@core/services';
import { ErrorBoundary } from '@core/services/Error/Error';

import './polyfills';

// Turn off Sentry in Playwright
if (!window.isPlaywright) {
  MLError.init();
}

const rootContainer = document.getElementById('root');

if (rootContainer === null) {
  throw new Error('Root container missing in index.html');
}

const root = createRoot(rootContainer);

// ApolloProvider and BrowserProvider are out of out MLError.Boundary because they block error bubbling
root.render(
  <ApolloProvider client={apolloClient}>
    <BrowserRouter>
      {/* Errors bubble to this ErrorBoundary if not caught in PageWrapper */}
      <ErrorBoundary
        fallback={
          <GenericFallbackFullPage centerContent showHeader withFooter />
        }
      >
        <LDProvider
          clientSideID={process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID as string}
          user={{
            anonymous: true,
            key: LD_CONSTANTS.preAuthUserKey,
          }}
        >
          <OktaSecurityProvider>
            <RiskAuthProvider riskAuth={RiskAuth}>
              <ScrollToTop>
                <ModalProvider>
                  <App />
                </ModalProvider>
              </ScrollToTop>
            </RiskAuthProvider>
          </OktaSecurityProvider>
        </LDProvider>
      </ErrorBoundary>
    </BrowserRouter>
  </ApolloProvider>,
);
