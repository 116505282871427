import { Link } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';

import {
  CLOSE_BUTTON_WIDTH,
  OpportunityTileCTA,
  OpportunityTileCloseIcon,
  OpportunityTileContainer,
  OpportunityTileDiagonalBackground,
} from './LayoutComponents';
import autopay from '@core/assets/autopay.svg';
import { CarouselTileProps } from '@core/components/Carousel/Carousel';

export const TRY_AUTOPAY_TILE_EVENT = 'Opportunity Tile: Try Autopay ';

export const TRY_AUTOPAY_TILE_DISMISSED_AT = 'Opportunity Tile: Try Autopay ';

const AutopayTile = ({
  onDismiss,
  isVisible,
  body,
  title,
  ctaText,
}: CarouselTileProps) => {
  const navigate = useNavigate();

  return (
    <OpportunityTileContainer
      backgroundColor="yellowWashed"
      isVisible={isVisible}
      trackingProperties={{ feature: TRY_AUTOPAY_TILE_EVENT }}
    >
      <OpportunityTileDiagonalBackground
        background="yellow"
        imageBottom={IMAGE_BOTTOM}
        imageRight={IMAGE_RIGHT}
        imgAlt="autopay calendar"
        imgSource={autopay}
        imgWidth={IMAGE_WIDTH}
      />
      <OpportunityTileCTA
        buttonLabel={ctaText}
        contentSafeMargin={CONTENT_SAFE_MARGIN}
        text={body}
        title={title}
        trackingName={TRY_AUTOPAY_TILE_EVENT}
        onButtonClick={() => navigate('../payments/autopay')}
      />
      <Link
        style={{ zIndex: 2 }}
        onPress={() => onDismiss(TRY_AUTOPAY_TILE_EVENT)}
      >
        <OpportunityTileCloseIcon />
      </Link>
    </OpportunityTileContainer>
  );
};

export default AutopayTile;

const IMAGE_WIDTH = 125;
const IMAGE_RIGHT = 6;
const CONTENT_SAFE_MARGIN = IMAGE_WIDTH - CLOSE_BUTTON_WIDTH - IMAGE_RIGHT;
const IMAGE_BOTTOM = 6;
