import { Link } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';

import {
  CLOSE_BUTTON_WIDTH,
  OpportunityTileCloseIcon,
  OpportunityTileContainer,
  OpportunityTileCTA,
  OpportunityTileDiagonalBackground,
} from '../OpportunityTileCarousel/OpportunityTiles/LayoutComponents';
import creditProtectionShield from '@core/assets/CreditProtectionShield.svg';
import { CarouselTileProps } from '@core/components/Carousel/Carousel';

const IMAGE_WIDTH = 80;
const IMAGE_RIGHT = 6;
const CONTENT_SAFE_MARGIN = IMAGE_WIDTH - CLOSE_BUTTON_WIDTH - IMAGE_RIGHT;
const IMAGE_BOTTOM = 6;

export const CREDIT_PROTECTION_TILE_EVENT =
  'Opportunity Tile: Credit Protection';

export const CREDIT_PROTECTION_TILE_DISMISSED_AT =
  'CREDIT_PROTECTION_TILE_DISMISSED_AT';

const CreditProtectionTile = ({
  onDismiss,
  isVisible,
  body,
  title,
  ctaText,
}: CarouselTileProps) => {
  const navigate = useNavigate();

  return (
    <OpportunityTileContainer
      backgroundColor="hazeLightest"
      isVisible={isVisible}
      trackingProperties={{
        feature: CREDIT_PROTECTION_TILE_EVENT,
        variant: 'MODAL_TILE_C',
      }}
    >
      <OpportunityTileDiagonalBackground
        background="hazeLightest"
        imageBottom={IMAGE_BOTTOM}
        imageRight={IMAGE_RIGHT}
        imgAlt="credit protection shield"
        imgSource={creditProtectionShield}
        imgWidth={IMAGE_WIDTH}
      />
      <OpportunityTileCTA
        buttonLabel={ctaText}
        contentSafeMargin={CONTENT_SAFE_MARGIN}
        text={body}
        title={title}
        trackingName={CREDIT_PROTECTION_TILE_EVENT}
        trackingProperties={{ variant: 'MODAL_TILE_C' }}
        onButtonClick={() => navigate('../credit-protection')}
      />
      <Link
        style={{
          zIndex: 2,
        }}
        onPress={() =>
          onDismiss(CREDIT_PROTECTION_TILE_DISMISSED_AT, {
            eventName: CREDIT_PROTECTION_TILE_EVENT,
            trackingProperties: { variant: 'MODAL_TILE_C' },
          })
        }
      >
        <OpportunityTileCloseIcon />
      </Link>
    </OpportunityTileContainer>
  );
};

export default CreditProtectionTile;
