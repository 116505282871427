import { useCallback, useEffect, useRef, useState } from 'react';

import { PaymentMethodName } from '../../Pay/Pay';
import { MAKE_DEBIT_CARD_PAYMENT } from '../gql/mutations';
import { MAKE_PAYMENT_QUERY, UPCOMING_PAYMENTS_QUERY } from '../gql/queries';
import PaymentSuccessContent from '../PaymentSuccessContent';
import { MakePaymentPaymentType } from '../types';
import { PaymentInput } from './PaymentInput';
import { PaymentReview } from './PaymentReview';
import SubmittingPayment from './SubmittingPayment';
import {
  CARD_PAYMENT_FAILED,
  CARD_PAYMENT_SUCCESS,
  CardPaymentState,
  INPUT_CARD_DETAILS,
  PayWithCardState,
  REVIEW_CARD_PAYMENT,
  SUBMIT_CARD_PAYMENT,
} from './types';
import GenericFallbackFull from '@core/components/GenericFallbacks/GenericFallbackFull';
import PageWrapper from '@core/components/Page/PageWrapper';
import { FormValues } from '@core/components/SecureForm';
import {
  MakePaymentQuery,
  PaymentType,
  Source,
  UpcomingPaymentsQuery,
} from '@core/graphql/globalTypes';
import { useTracking } from '@core/services/TrackService/useTracking';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import {
  getSubmitCardFailureMessage,
  isSubmitCardPaymentSuccess,
} from '@payments/components/BankCardDetails/helpers';
import {
  FieldName,
  IBankCardDetails,
  SubmitCardPaymentResponse,
} from '@payments/components/BankCardDetails/types';

import './payWithCard.css';
import LoadingSpinner from '@core/components/General/LoadingSpinner';

const PayWithCard = () => {
  const { data: makePaymentData, loading: makePaymentLoading } =
    useAccountIdQuery<MakePaymentQuery>(MAKE_PAYMENT_QUERY);

  const {
    data: upcomingPaymentsData,
    loading: upcomingPaymentsLoading,
    error: upcomingPaymentsError,
  } = useAccountIdQuery<UpcomingPaymentsQuery>(UPCOMING_PAYMENTS_QUERY);

  const [paymentType, setPaymentType] = useState<MakePaymentPaymentType | null>(
    null,
  );

  const [amount, setAmount] = useState<number>(0);
  const [response, setResponse] = useState<SubmitCardPaymentResponse>();
  const [transactionID, setTransactionID] = useState<string>('');
  const { trackClick } = useTracking();

  const [payWithCardState, setPayWithCardState] =
    useState<PayWithCardState>(INPUT_CARD_DETAILS);

  useEffect(() => {
    if (response) {
      if (isSubmitCardPaymentSuccess(response) && Boolean(response.id)) {
        setTransactionID(response.id);

        setPayWithCardState(CARD_PAYMENT_SUCCESS);
      } else {
        setPayWithCardState(CARD_PAYMENT_FAILED);
      }
    }
  }, [response]);

  const bankCardFormRef = useRef<IBankCardDetails>(null);

  const onComplete = useCallback((resp: SubmitCardPaymentResponse) => {
    setResponse(resp);
  }, []);

  const setReviewingAndDiscardResponse = () => {
    if (payWithCardState.state !== CardPaymentState.REVIEW_CARD_PAYMENT) {
      setResponse(undefined);
    }

    setPayWithCardState(REVIEW_CARD_PAYMENT);
  };

  if (makePaymentLoading || upcomingPaymentsLoading) {
    return <LoadingSpinner />;
  }

  const {
    id: accountId,
    cardDetails,
    paymentInfo,
    autopay = [],
    statuses,
  } = makePaymentData?.account || {};

  const last4 = cardDetails?.last4;
  const upcomingPayments = upcomingPaymentsData?.account?.upcomingPayments;

  const currentBalance =
    upcomingPaymentsData?.account?.balanceInfo?.currentBalance;

  function setAmountData(pType: PaymentType, amt: number | null) {
    setPaymentType(pType);

    setAmount(amt || 0);
  }

  function handleSubmit() {
    if (!accountId || !bankCardFormRef.current) {
      throw new Error(
        `Could not submit payment for accountId=${accountId}. ${
          !bankCardFormRef.current && 'Card submission form was not loaded.'
        }`,
      );
    }

    trackClick({
      name: 'Debit Card Payment Submission',
      feature: 'Make Debit Card Payment',
      metadata: {
        payFrom: 'DEBIT',
        selectAmount: amount,
        selectDate: 'IMMEDIATE',
      },
    });

    if (payWithCardState.state == CardPaymentState.REVIEW_CARD_PAYMENT) {
      setPayWithCardState(SUBMIT_CARD_PAYMENT);

      bankCardFormRef.current.submitCardPayment(cardData, onComplete);
    } else {
      setPayWithCardState(INPUT_CARD_DETAILS);
    }
  }

  const cardData = (values: FormValues) => ({
    query: MAKE_DEBIT_CARD_PAYMENT,
    variables: {
      accountId,
      amount,
      source: Source.Web,
      aliasedHolderName: values[FieldName.HolderName],
      aliasedCardNumber: values[FieldName.CardNumber],
      aliasedExpiration: values[FieldName.Expiration],
      aliasedPostalCode: values[FieldName.PostalCode],
      aliasedSecurityCode: values[FieldName.SecurityCode],
    },
  });

  if (!accountId || !paymentInfo) {
    return <GenericFallbackFull />;
  }

  const state = payWithCardState.state;

  const paymentInputDisplay =
    state === CardPaymentState.INPUT_CARD_DETAILS ? '' : 'hidden';

  const paymentReviewDisplay =
    (
      state === CardPaymentState.REVIEW_CARD_PAYMENT ||
      state === CardPaymentState.CARD_PAYMENT_FAILED
    ) ?
      ''
    : 'hidden';

  const submitPaymentDisplay =
    state === CardPaymentState.SUBMIT_CARD_PAYMENT ? '' : 'hidden';

  const paymentResultDisplay =
    state === CardPaymentState.CARD_PAYMENT_SUCCESS ? '' : 'hidden';

  return (
    <div className="PayWithCard">
      <div className={paymentInputDisplay}>
        <PaymentInput
          amount={amount}
          autopay={autopay}
          bankCardFormRef={bankCardFormRef}
          currentBalance={currentBalance || 0}
          error={upcomingPaymentsError}
          loading={upcomingPaymentsLoading}
          paymentInfo={paymentInfo}
          paymentType={paymentType}
          setAmountData={setAmountData}
          setReviewing={setReviewingAndDiscardResponse}
          statuses={statuses}
          upcomingPayments={upcomingPayments}
        />
      </div>
      <div className={paymentReviewDisplay}>
        <PaymentReview
          acctLast4={last4 || undefined}
          amount={amount}
          handleSubmit={handleSubmit}
          submissionError={getSubmitCardFailureMessage(response)}
        />
      </div>
      <div className={submitPaymentDisplay}>
        <SubmittingPayment />
      </div>
      <div className={paymentResultDisplay}>
        {Boolean(response) && (
          <PaymentSuccessContent
            amount={amount}
            confirmationNumber={transactionID}
            fundingAccount={null}
            isImmediate={true}
            paymentMethod={PaymentMethodName.DEBIT_CARD}
          />
        )}
        {!response && state === CardPaymentState.CARD_PAYMENT_SUCCESS && (
          <GenericFallbackFull />
        )}
      </div>
    </div>
  );
};

const PayWithCardPage = () => (
  <PageWrapper
    pageTitle={{ primaryText: 'Make a payment', useLast4: true }}
    trackingProperties={{
      featureName: 'Payments: Make Payment',
      pageName: 'Make a Payment: Pay with Card',
    }}
  >
    <PayWithCard />
  </PageWrapper>
);

export default PayWithCardPage;
