import Modal from '../General/Modal/Modal';
import ResponsiveTypography from '../General/ResponsiveTypography';

//exporting for tests
export const REFERRAL_TERMS_MODAL_TITLE = 'Mission Lane Referral Bonus Terms';

interface ReferralTermsModalProps {
  isOpen: boolean;
  onClose: () => void;
  maximumStatementCredit: number;
}

const ReferralsTermsModal = ({
  isOpen,
  onClose,
  maximumStatementCredit,
}: ReferralTermsModalProps) => {
  return (
    <Modal
      headerText={REFERRAL_TERMS_MODAL_TITLE}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="pa3 vh-75-l">
        <ResponsiveTypography
          type="PARAGRAPH"
          mobileLevel="P1"
          desktopLevel="P3"
        >
          The Mission Lane Referral Bonus Terms (“Referral Terms”) is a separate
          and independent agreement from the Mission Lane Visa® Credit Card
          Agreement (the “Agreement”); however, it is subject to the Arbitration
          Agreement with Class Action and Jury Trial Waiver section of the
          Agreement, which is incorporated herein.  In the event of a conflict
          between the Agreement and these Referral Terms, the Agreement will
          govern, except the Referral Terms will govern in any matter relating
          to the Referral Program (defined below).   Defined terms herein are as
          defined in the Mission Lane Visa® Credit Card Agreement unless
          specifically defined herein.
          <br />
          <br />
          We from time to time may offer cardholders the opportunity to receive
          a statement credit (“Referral Bonus”) in the event they refer to us an
          individual who applies using the correct referral link and later  then
          obtains a Mission Lane Visa® Credit Card (the “Referral Program”). 
          By sending a Referral Program referral, you’re agreeing to be bound by
          these Referral Terms.   We may modify, amend, suspend or terminate the
          Referral Program and these Referral Terms at any time and at our sole
          discretion.  Our decisions regarding the Referral Program are final.
          <br />
          <br />
          Because the credit application and approval process is confidential,
          you will not be notified of whether we approve or decline any
          applications from individuals you refer or whether those individuals
          obtain a Mission Lane Visa® Credit Card.
          <br />
          <br />
          Referral Bonuses will appear on your statement as a statement credit. 
          Please allow up to 8 weeks after the Referral Bonus is earned for it
          to appear on your statement as a statement credit. The total maximum
          amount of Referral Bonuses that you can earn in a given calendar year
          is ${maximumStatementCredit}.   Once you reach this limit, Referral
          Bonuses thereafter cannot be earned.  Referral Bonus statement credits
          may be taxable as income. You should contact a qualified tax advisor
          concerning any tax impact. You are solely liable for any applicable
          taxes.
          <br />
          <br />
          In order to be eligible to earn a Referral Bonus, your Mission Lane
          Visa® Credit Card (“Account”)Account must be open and in Good
          Standing.  “Good Standing” means the Account is not over-limit, not
          delinquent, not bankrupt, not currently restricted by the issuer, has
          passed our ability to pay analysis, and is being used in accordance
          with the Agreement. Any earned Referral Bonus will be forfeited if,
          before such Referral Bonus is applied to the Account, the Account is
          closed, goes into Default under the Agreement or you are otherwise
          disqualified from participation in the Referral Program. You will not
          be entitled to compensation if a Referral Bonus is forfeited for any
          reason.
          <br />
          <br />
          We reserve the right to disqualify customers from earning Referral
          Bonuses in the event of fraud, abuse of Referral Bonus program
          privileges or violation of the Referral Terms as determined at our
          sole discretion. Such disqualification may be temporary or permanent
          and may result in the forfeiture of any Referral Bonus that was earned
          but not yet credited to the Account.
          <br />
          <br />
          Except for the Referral Bonus applied to your Account and reflected on
          your statement balance, a Referral Bonus has no cash or other value,
          and may be used only as described in these Referral Terms. A Referral
          Bonus cannot be assigned, transferred, gifted, sold, attached, pledged
          or bartered under any circumstance, including, without limitation, in
          case of disability or death, by operation of law or in connection with
          a domestic relations or other legal dispute.
        </ResponsiveTypography>
      </div>
    </Modal>
  );
};

export default ReferralsTermsModal;
