import { Colors, P3 } from '@missionlane/compass-ui';
import {
  ClipOfferPaymentStatus,
  ClipOfferStatusCodes,
} from '@clip/ClipProgress/hooks/types/ClipOffer';
import './ClipTracker.css';
import useClipOfferV2 from '@clip/ClipProgress/hooks/useClipOfferV2';

export const CLIP_TRACKER_TEST_ID = 'CLIP_TRACKER_TEST_ID';
export const CLIP_TRACKER_NODE_CONTAINER_TEST_ID =
  'CLIP_TRACKER_NODE_CONTAINER_TEST_ID';

const ClipTracker = () => {
  const clipOffer = useClipOfferV2();

  const isVisible =
    clipOffer?.clipStatus === ClipOfferStatusCodes.IN_PROGRESS ||
    clipOffer?.clipStatus === ClipOfferStatusCodes.EVALUATION_PENDING ||
    clipOffer?.clipStatus === ClipOfferStatusCodes.PENDING_PAYMENT;

  if (!isVisible) return null;

  const isTrackItemsValid =
    clipOffer?.trackerItems &&
    clipOffer?.trackerItems.length === 6 &&
    // we only show the first 6 months, this covers the overlimit case
    clipOffer?.currentStatementNumber <= 7;

  if (!isTrackItemsValid) return null;

  const hasWarningBar =
    clipOffer?.isCurrentPaymentPending || clipOffer?.isInGracePeriod;

  const paidPercentage =
    clipOffer?.trackerItems!.filter(
      (item, i) =>
        item.paymentStatus === ClipOfferPaymentStatus.COMPLETE && i !== 0,
    ).length * 20;
  const pendingPercentage =
    (
      clipOffer?.trackerItems!.filter(
        (item, i) =>
          item.paymentStatus === ClipOfferPaymentStatus.PENDING && i !== 0,
      ).length * 20 || hasWarningBar
    ) ?
      20
    : 0;

  const upcomingPercentage =
    clipOffer?.trackerItems!.filter(
      (item, i) =>
        (item.paymentStatus === ClipOfferPaymentStatus.UPCOMING ||
          item.paymentStatus === ClipOfferPaymentStatus.FUTURE) &&
        i !== 0,
    ).length * 20;

  return (
    <div
      className="flex justify-between flex-column mb3"
      data-testid={CLIP_TRACKER_TEST_ID}
    >
      <div className="clip-tracker-bar-container">
        <div
          className="clip-tracker-bar-completed-bar"
          style={{
            width: `calc(${paidPercentage}% - 5px)`,
          }}
        />

        <div
          className="clip-tracker-bar-pending-bar"
          style={{
            width: `calc(${pendingPercentage}% - 5px)`,
          }}
        />

        <div
          className="clip-tracker-bar-upcoming-bar"
          style={{
            width: `calc(${upcomingPercentage}% - 5px)`,
          }}
        />
      </div>

      <div
        className="flex justify-between"
        data-testid={CLIP_TRACKER_NODE_CONTAINER_TEST_ID}
      >
        {clipOffer?.trackerItems?.map(({ formattedMonth, paymentStatus }) => {
          const isActive = paymentStatus === ClipOfferPaymentStatus.COMPLETE;
          const isPending = paymentStatus === ClipOfferPaymentStatus.PENDING;

          return (
            <div
              key={formattedMonth}
              className="z-1 flex flex-column items-center"
            >
              <div className="clip-tracker-bar-node-offset">
                <div
                  className="clip-tracker-bar-node"
                  style={{
                    backgroundColor:
                      isActive ? Colors.green
                      : isPending ? Colors.yellow
                      : Colors.greyLightest,
                  }}
                />
              </div>
              <P3 style={{ lineHeight: 0 }}>{formattedMonth}</P3>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ClipTracker;
