import { Link } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';

import { useAccount } from '../Auth/AccountContext';
import {
  OpportunityTileContainer,
  OpportunityTileDiagonalBackground,
  OpportunityTileCTA,
  OpportunityTileCloseIcon,
  CLOSE_BUTTON_WIDTH,
} from '../OpportunityTileCarousel/OpportunityTiles/LayoutComponents';

import type { CarouselTileProps } from '../Carousel/Carousel';
import OnboardingSVG from '@core/assets/onboarding.svg';

const IMAGE_WIDTH = 127;
const IMAGE_RIGHT = 6;
const IMAGE_BOTTOM = 8;
const CONTENT_SAFE_MARGIN = IMAGE_WIDTH - CLOSE_BUTTON_WIDTH - IMAGE_RIGHT;

export const ONBOARDING_TILE_EVENT = 'Opportunity Tile: Onboarding';

export const ONBOARDING_TILE_DISMISSED_AT = 'Opportunity Tile: Onboarding';

const OnboardingOpportunityTile = ({
  onDismiss,
  isVisible,
  body,
  title,
  ctaText,
}: CarouselTileProps) => {
  const { accountId } = useAccount();
  const navigate = useNavigate();

  return (
    <OpportunityTileContainer
      backgroundColor="greenWashed"
      isVisible={isVisible}
      trackingProperties={{ feature: ONBOARDING_TILE_EVENT }}
    >
      <OpportunityTileDiagonalBackground
        background="green"
        diagonalHeight={441}
        diagonalRight={-190}
        diagonalWidth={342}
        imageBottom={IMAGE_BOTTOM}
        imageRight={IMAGE_RIGHT}
        imgAlt="credit protection shield"
        imgSource={OnboardingSVG}
        imgWidth={IMAGE_WIDTH}
      />
      <OpportunityTileCTA
        buttonLabel={ctaText}
        contentSafeMargin={CONTENT_SAFE_MARGIN}
        text={body}
        title={title}
        trackingName={ONBOARDING_TILE_EVENT}
        onButtonClick={() => navigate(`/account/${accountId}/my-progress`)}
      />
      <Link
        style={styles.link}
        onPress={() => onDismiss(ONBOARDING_TILE_EVENT)}
      >
        <OpportunityTileCloseIcon />
      </Link>
    </OpportunityTileContainer>
  );
};

export default OnboardingOpportunityTile;

const styles = {
  link: {
    zIndex: 2,
  },
};
