import { Link } from '@missionlane/compass-ui';
import dayjs from 'dayjs';

import mlSecondCardOffer from '@core/assets/mlSecondCardOffer.svg';
import { CarouselTileProps } from '@core/components/Carousel/Carousel';
import {
  CLOSE_BUTTON_WIDTH,
  OpportunityTileCTA,
  OpportunityTileCloseIcon,
  OpportunityTileContainer,
  OpportunityTileDiagonalBackground,
} from '@core/components/OpportunityTileCarousel/OpportunityTiles/LayoutComponents';
import { TrackService } from '@core/services';

export const REACTIVE_MULTICARD_TILE_EVENT = 'REACTIVE_MULTICARD_OFFER';

export const REACTIVE_MULTICARD_TILE_DISMISSED_AT = 'REACTIVE_MULTICARD_OFFER';

const ReactiveMulticardTile = ({
  onDismiss,
  isVisible,
  body,
  title,
  ctaText,
}: CarouselTileProps) => {
  const onClickCta = () => {
    TrackService.trackClick('See If You Qualify', {
      feature: REACTIVE_MULTICARD_TILE_EVENT,
    });

    window
      .open(
        'https://apply.missionlane.com/prequalification?invite-code=MLPQRC7890',
        '_blank',
      )
      ?.focus();

    localStorage.setItem(REACTIVE_MULTICARD_TILE_EVENT, dayjs().toString());
  };

  return (
    <OpportunityTileContainer
      backgroundColor="tealWashed"
      isVisible={isVisible}
      trackingProperties={{ feature: REACTIVE_MULTICARD_TILE_EVENT }}
    >
      <OpportunityTileDiagonalBackground
        background="green"
        imageBottom={IMAGE_BOTTOM}
        imageRight={IMAGE_RIGHT}
        imgAlt="new ML credit card"
        imgSource={mlSecondCardOffer}
        imgWidth={IMAGE_WIDTH}
      />
      <OpportunityTileCTA
        buttonLabel={ctaText}
        contentSafeMargin={CONTENT_SAFE_MARGIN}
        text={body}
        title={title}
        trackingName={REACTIVE_MULTICARD_TILE_EVENT}
        onButtonClick={onClickCta}
      />
      <Link
        style={{
          zIndex: 2,
        }}
        onPress={() => onDismiss(REACTIVE_MULTICARD_TILE_EVENT)}
      >
        <OpportunityTileCloseIcon />
      </Link>
    </OpportunityTileContainer>
  );
};

export default ReactiveMulticardTile;

const IMAGE_WIDTH = 87;
const IMAGE_RIGHT = 10;
const CONTENT_SAFE_MARGIN = IMAGE_WIDTH - CLOSE_BUTTON_WIDTH;
const IMAGE_BOTTOM = 10;
