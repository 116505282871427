import { gql } from '@apollo/client';

export const opportunityTilesQuery = gql`
  query OpportunityTiles($accountId: String!, $dismissedAt: DismissedAt) {
    opportunityTiles(accountId: $accountId, dismissedAt: $dismissedAt) {
      tiles {
        body
        ctaText
        id
        title
      }
      variant
    }
  }
`;
