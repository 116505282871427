import cx from 'classnames';
import dayjs from 'dayjs';
import { ReactNode, useEffect } from 'react';
import { Icon, P3, P4, Spacer } from '@missionlane/compass-ui';

import { gql, useLazyQuery } from '@apollo/client';
import ResponsiveTypography from '../ResponsiveTypography';
import { FooterNav } from './FooterNav';
import { FooterLogo } from './FooterLogo';
import { SocialMediaLinks } from './SocialMediaLinks';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';
import { FooterQuery, FooterQueryVariables } from '@core/graphql/globalTypes';
import { MLError } from '@core/services';
import { recoveriesPhone, cardSupportPhone } from '@core/utils/contact';

import { useTracking } from '@core/services/TrackService/useTracking';
import { activeAccountIdVar } from '@core/components/Navigation/NavContainer';

const FOOTER_QUERY = gql`
  query Footer($accountId: String!) {
    account(accountId: $accountId) {
      id
      statuses {
        isChargedOff
      }
    }
  }
`;

/* Temporary font-family wrapper till we get this font in compass-ui */
const MonospaceText = ({ children }: { children: ReactNode }) => (
  <span className="monospace">{children}</span>
);

interface Props {
  contentClassName?: string;
}
const Footer = ({ contentClassName }: Props) => {
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();
  const { isMobile } = useUserDevice();
  const ResponsiveParagraph = isMobile ? P3 : P4;
  const { trackClick } = useTracking();

  const [footerQuery, { data, loading, error }] = useLazyQuery<
    FooterQuery,
    FooterQueryVariables
  >(FOOTER_QUERY, {
    variables: { accountId: activeAccountIdVar() || '' },
  });

  const supportPhoneNumber =
    data?.account?.statuses?.isChargedOff ? recoveriesPhone : cardSupportPhone;

  useEffect(() => {
    if (activeAccountIdVar()) {
      footerQuery();
    }
  }, [activeAccountIdVar()]);

  if (error)
    MLError.report(
      { name: 'Footer Query', error },
      {
        tags: {
          features: 'Footer',
        },
      },
    );

  return (
    <footer className="w-100 bg-pine">
      <div
        className={cx(
          'flex ph4',
          isMobile ? 'pv5 flex-column' : 'pv6',
          contentClassName,
        )}
      >
        <div className="flex flex-column">
          <FooterNav
            trackingProperties={{
              customerId: customerAndAccountIds.customerId,
              accountId: activeAccountIdVar(),
            }}
          />
          <ResponsiveParagraph color="white">
            <MonospaceText>
              &copy; {dayjs().format('YYYY')} Mission Lane LLC.{' '}
              <span className="nowrap">All Rights Reserved.</span>{' '}
              <span className="nowrap">NMLS #1857501</span>
            </MonospaceText>
          </ResponsiveParagraph>
          <div className="flex measure-wide">
            <ResponsiveParagraph color="white">
              <MonospaceText>
                Mission Lane LLC does business in Arizona under the trade name
                Mission Lane Card Services LLC.
              </MonospaceText>
            </ResponsiveParagraph>
          </div>
        </div>

        {!loading && (
          <div
            className={cx(
              'tr ml-auto flex flex-column items-end',
              isMobile && 'mt5',
            )}
          >
            <FooterLogo
              trackingProperties={{
                customerId: customerAndAccountIds.customerId,
                accountId: activeAccountIdVar(),
              }}
            />
            <SocialMediaLinks />
            <div
              className={cx('flex pv3 items-center')}
              onClick={() => {
                if (isMobile) {
                  window.open(`tel:+1-${supportPhoneNumber}`);
                  trackClick({
                    name: `Call Support: ${supportPhoneNumber}`,
                    feature: 'Footer',
                  });
                }
              }}
            >
              <Icon name="phone" color="white" size="s" />
              <Spacer direction="horizontal" size="s" />
              <ResponsiveTypography
                type="PARAGRAPH"
                mobileLevel="P1"
                desktopLevel="P3"
                color="white"
                style={{
                  margin: 0,
                  lineHeight: 0,
                }}
              >
                {supportPhoneNumber}
              </ResponsiveTypography>
            </div>
          </div>
        )}
      </div>
    </footer>
  );
};

export default Footer;
