import { Icon, P3 } from '@missionlane/compass-ui';
import { ClipOfferPaymentStatus } from '../../hooks/types/ClipOffer';
import useClipOfferV2 from '@clip/ClipProgress/hooks/useClipOfferV2';

const PaymentPending = ({ children }: { children: React.ReactNode }) => {
  const clipOffer = useClipOfferV2();
  const hasPendingPayment = clipOffer?.trackerItems?.find(
    (trackerItem) =>
      trackerItem.paymentStatus === ClipOfferPaymentStatus.PENDING,
  );

  if (!clipOffer?.isCurrentPaymentPending && !hasPendingPayment) {
    return null;
  }

  return (
    <div className="flex items-center mb3">
      <Icon name="alertCircle" color="mustard" size="l" />
      <P3 color="mustard" style={{ margin: 0, marginLeft: 16, maxHeight: 48 }}>
        {children}
      </P3>
    </div>
  );
};

export default PaymentPending;
