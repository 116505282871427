import { gql, useQuery } from '@apollo/client';
import { ColorNames, Link, LoadingIndicator } from '@missionlane/compass-ui';

import mlGoldCardOffer from '@core/assets/mlGoldCardOffer.svg';
import mlSilverCardOffer from '@core/assets/mlSilverCardOffer.svg';
import { CarouselTileProps } from '@core/components/Carousel/Carousel';
import {
  CLOSE_BUTTON_WIDTH,
  OpportunityTileCTA,
  OpportunityTileCloseIcon,
  OpportunityTileContainer,
  OpportunityTileDiagonalBackground,
  OpportunityTileGradient,
} from '@core/components/OpportunityTileCarousel/OpportunityTiles/LayoutComponents';
import {
  ProactiveMulticardOffer as ProactiveMulticardOfferType,
  ProactiveMulticardTileQuery,
  ProactiveMulticardTileQueryVariables,
} from '@core/graphql/globalTypes';
import { TrackService } from '@core/services';

export const PROACTIVE_MULTICARD_TILE_EVENT = 'PROACTIVE_MULTICARD_OFFER';

const PROACTIVE_MULTICARD_APPLY_LINK = `${process.env.REACT_APP_APPLY_URL}/flow/application`;

export const PROACTIVE_MULTICATD_TILE_QUERY = gql`
  query ProactiveMulticardTile {
    proactiveOffers {
      eligible
      expirationDate
      offers {
        offerType
        rewardsPercentage
        categoryRewardsPercentage
        totalCategoryRewardsPercentage
        rewardCategories
        maxCreditLimit
      }
    }
  }
`;

const OFFER_TYPE = {
  CASHBACK: 'CASHBACK',
  CATEGORY_REWARDS: 'CATEGORY_REWARDS',
} as const;

type OfferType = keyof typeof OFFER_TYPE;

export const isOfferType = (offerType = ''): offerType is OfferType => {
  const maybeOfferType = offerType as OfferType;

  return Boolean(OFFER_TYPE[maybeOfferType]);
};

export const getActiveOfferBg = (offer: ProactiveMulticardOfferType | null) => {
  if (!offer || !isOfferType(offer.offerType)) {
    return null;
  }

  const tileBackground: ProactiveMulticardTileBackground =
    offer.offerType === 'CASHBACK' ?
      {
        image: mlSilverCardOffer,
        backgroundColor: 'greyWashed',
        gradient: 'silverGradient',
      }
    : {
        image: mlGoldCardOffer,
        backgroundColor: 'yellowWashed',
        gradient: 'goldGradient',
      };

  return tileBackground;
};

type ProactiveMulticardTileBackground = {
  backgroundColor: ColorNames;
  image: string;
  gradient: OpportunityTileGradient;
};

const ProactiveMulticardTile = ({
  onDismiss,
  isVisible,
  body,
  title,
  ctaText,
}: CarouselTileProps) => {
  const { data, loading } = useQuery<
    ProactiveMulticardTileQuery,
    ProactiveMulticardTileQueryVariables
  >(PROACTIVE_MULTICATD_TILE_QUERY);

  const onClickCta = () => {
    TrackService.trackClick('See If You Qualify', {
      feature: PROACTIVE_MULTICARD_TILE_EVENT,
    });

    window.open(PROACTIVE_MULTICARD_APPLY_LINK, '_blank')?.focus();
  };

  const proactiveOffers = data?.proactiveOffers?.offers;
  const offerBg = proactiveOffers && getActiveOfferBg(proactiveOffers[0]);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (!offerBg) {
    // Unlikely to happen, but if there is no offer content, we should not render the tile
    // Apollo should handle this case and not return this tile if there is no offer
    return null;
  }

  return (
    <OpportunityTileContainer
      backgroundColor={offerBg.backgroundColor}
      isVisible={isVisible}
      trackingProperties={{ feature: PROACTIVE_MULTICARD_TILE_EVENT }}
    >
      <OpportunityTileDiagonalBackground
        background={offerBg.gradient}
        imageBottom={IMAGE_BOTTOM}
        imageRight={IMAGE_RIGHT}
        imgAlt="new ML credit card"
        imgSource={offerBg.image}
        imgWidth={IMAGE_WIDTH}
      />
      <OpportunityTileCTA
        buttonLabel={ctaText}
        contentSafeMargin={CONTENT_SAFE_MARGIN}
        text={body}
        title={title}
        trackingName={PROACTIVE_MULTICARD_TILE_EVENT}
        onButtonClick={onClickCta}
      />
      <Link
        style={{
          zIndex: 2,
        }}
        onPress={() => onDismiss(PROACTIVE_MULTICARD_TILE_EVENT)}
      >
        <OpportunityTileCloseIcon />
      </Link>
    </OpportunityTileContainer>
  );
};

export default ProactiveMulticardTile;

const IMAGE_WIDTH = 87;
const IMAGE_RIGHT = 10;
const CONTENT_SAFE_MARGIN = IMAGE_WIDTH - CLOSE_BUTTON_WIDTH;
const IMAGE_BOTTOM = 10;
