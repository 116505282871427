import { Colors, Link } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';

import {
  OpportunityTileCTA,
  OpportunityTileCloseIcon,
  OpportunityTileContainer,
} from './LayoutComponents';
import bankAndCoins from '@core/assets/addPaymentMethod.png';
import { CarouselTileProps } from '@core/components/Carousel/Carousel';

export const ADD_PAYMENT_METHOD_TILE_EVENT =
  'Opportunity Tile: Add Payment Method';

export const ADD_PAYMENT_METHOD_TILE_DISMISSED_AT =
  'Opportunity Tile: Add Payment Method';

const AddPaymentMethodTile = ({
  onDismiss,
  isVisible,
  body,
  title,
  ctaText,
}: CarouselTileProps) => {
  const navigate = useNavigate();

  return (
    <OpportunityTileContainer
      backgroundColor="tealWashed"
      isVisible={isVisible}
      trackingProperties={{ feature: ADD_PAYMENT_METHOD_TILE_EVENT }}
    >
      <div
        style={{
          position: 'absolute',
          width: `${BACKGROUND_SAFE_WIDTH}px`,
          height: '200px',
          background: Colors.pineLight,
          right: 0,
          top: 0,
        }}
      />
      <img
        alt="bank and coins"
        src={bankAndCoins}
        style={{
          position: 'absolute',
          width: `${IMAGE_WIDTH}px`,
          right: IMAGE_RIGHT,
          bottom: IMAGE_BOTTOM,
        }}
      />
      <OpportunityTileCTA
        buttonLabel={ctaText}
        contentSafeMargin={CONTENT_SAFE_MARGIN}
        text={body}
        title={title}
        trackingName={ADD_PAYMENT_METHOD_TILE_EVENT}
        onButtonClick={() =>
          navigate('../manage-bank-accounts', { preventScrollReset: false })
        }
      />
      <Link
        style={{ zIndex: 2 }}
        onPress={() => onDismiss(ADD_PAYMENT_METHOD_TILE_EVENT)}
      >
        <OpportunityTileCloseIcon />
      </Link>
    </OpportunityTileContainer>
  );
};

export default AddPaymentMethodTile;

const IMAGE_WIDTH = 90;
const IMAGE_RIGHT = 30;
const IMAGE_BOTTOM = 12;
const BACKGROUND_SAFE_WIDTH = IMAGE_WIDTH / 2 + IMAGE_RIGHT;
const CONTENT_SAFE_MARGIN = IMAGE_WIDTH - IMAGE_RIGHT;
