import { B, H3, P1, P3, Spacing } from '@missionlane/compass-ui';
import { gql, NetworkStatus } from '@apollo/client';
import { Navigate } from 'react-router-dom';
import { useState } from 'react';
import PageWrapper from '../Page/PageWrapper';
import GenericFallbackFull from '../GenericFallbacks/GenericFallbackFull';
import LoadingSpinner from '../General/LoadingSpinner';
import { NoRedeemableRewardsMessage } from './NoRedeemableRewardsMessage';
import PendingRewardsRedemption from './PendingRewardsRedemption';
import RewardsRedemptionForm from './RewardsRedemptionForm';
import { REWARDS_PAGE_NAME, REWARDS_REDEMPTION_FEATURE_NAME } from './const';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import { RewardsQuery, RewardsQueryVariables } from '@core/graphql/globalTypes';
import { centsToDollars } from '@core/utils/centsToDollars';
import isNil from '@core/utils/isNil';
import { MLError } from '@core/services';

export const getRewards = gql`
  query Rewards($accountId: String!) {
    rewards(accountId: $accountId) {
      accountId
      isRewardsCard
      payable {
        amount
        isRedeemable
        pendingAmount
      }
    }
  }
`;

export interface RewardsPageProps {
  setBanners: (banners: React.ComponentType[]) => void;
}

const RewardsPageContent = ({ setBanners }: RewardsPageProps) => {
  const { data, loading, error, networkStatus } = useAccountIdQuery<
    RewardsQuery,
    RewardsQueryVariables
  >(getRewards, {
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      if (error instanceof Error)
        MLError.report(
          { name: error.name, error },
          {
            tags: {
              feature: REWARDS_REDEMPTION_FEATURE_NAME,
            },
          },
        );
    },
  });

  if (loading) return <LoadingSpinner />;

  if (data?.rewards?.isRewardsCard === false) return <Navigate to="../" />;

  if (error) throw error;

  // if theres no payables object or if the user doesn't have a payable amount we display an error
  // This will still display 0.00
  if (!data?.rewards?.payable || isNil(data?.rewards?.payable.amount))
    return <GenericFallbackFull />;

  const { amount, isRedeemable, pendingAmount } = data.rewards.payable;

  return (
    <div data-testId="RewardsPageContent" style={{ width: 325 }}>
      <div style={{ marginBottom: Spacing.xm }}>
        <H3>Rewards</H3>
      </div>
      <div className="flex">
        <div className="flex flex-column">
          <P1
            style={{
              margin: 0,
            }}
            color="ink"
          >
            <B>{centsToDollars(amount)}</B>
          </P1>
          <P3 style={{ margin: 0 }}>Cash Back Balance</P3>
        </div>
      </div>
      {!isNil(pendingAmount) && pendingAmount > 0 ?
        <PendingRewardsRedemption pendingAmount={pendingAmount} />
      : <div className="mt3" />}

      {/* isRedeemable is false when amount is $0 but we don't want to show 
      bad account status copy in that scenario */}
      {!isRedeemable && amount > 0 ?
        <NoRedeemableRewardsMessage />
      : <RewardsRedemptionForm
          setBanners={setBanners}
          amount={amount}
          hasMarginTop={!pendingAmount}
          isLoadingRefetch={networkStatus === NetworkStatus.refetch}
        />
      }
    </div>
  );
};

const RewardsPage = () => {
  const [banners, setBanners] = useState<React.ComponentType[]>([]);
  return (
    <PageWrapper
      banners={banners}
      trackingProperties={{
        featureName: REWARDS_REDEMPTION_FEATURE_NAME,
        pageName: `${REWARDS_REDEMPTION_FEATURE_NAME}: ${REWARDS_PAGE_NAME}`,
      }}
    >
      <RewardsPageContent setBanners={setBanners} />
    </PageWrapper>
  );
};

export default RewardsPage;
