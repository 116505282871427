import { gql } from '@apollo/client';

export const clipOfferQuery = gql`
  query ClipOffer($customerId: String!, $accountId: String!) {
    clipOffer(accountId: $accountId) {
      eligible
      experience
      currentPaymentStreak
      currentPaymentPending
      status
      neverActive
      currentStatementNumber
      lineIncreaseAmount
      evaluationDate
      trackerItems {
        statementNumber
        paymentStatus
        date
      }
    }

    account(accountId: $accountId) {
      pricingDetails {
        creditLimit
      }
      autopay {
        id
      }
    }

    customer(id: $customerId) {
      contactInfo {
        firstName
      }
    }
  }
`;
