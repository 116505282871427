import { Fragment } from 'react';
import { Spacer } from '@missionlane/compass-ui';
import { DescriptionList } from '../../../General/DescriptionList/DescriptionList';
import { AccountDetailsPage } from '@core/graphql/globalTypes';

interface RewardsCategoriesProps {
  categoryName: AccountDetailsPage.Rewards['categoryName'];
  rewardsRate: AccountDetailsPage.Rewards['rewardsRate'];
  categoryRewardsRate: AccountDetailsPage.Rewards['categoryRewardsRate'];
}
export const RewardsCategories = ({
  categoryName,
  categoryRewardsRate,
  rewardsRate,
}: RewardsCategoriesProps) => {
  if (rewardsRate) {
    return (
      <DescriptionList>
        {categoryName && categoryRewardsRate && (
          <Fragment>
            <DescriptionList.DetailItem
              label={categoryName}
              value={categoryRewardsRate}
            />
          </Fragment>
        )}
        <Spacer size="s" />
        <Fragment>
          <DescriptionList.DetailItem
            label="All other purchases"
            value={rewardsRate}
          />
        </Fragment>
      </DescriptionList>
    );
  }
  return null;
};
