import cx from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { gql } from '@apollo/client';
import ActivationBannerKard from '../ActivationBannerKard';
import OpportunityTileCarousel from '../../OpportunityTileCarousel/OpportunityTileCarousel';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import { AccountSummaryCard } from '@core/components/AccountSummaryCard/AccountSummaryCard';
import { AccountInformationContext } from '@core/components/AccountInformation/AccountInformationProvider';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';
import PaymentKard from '@payments/components/PaymentKard/PaymentKard';
import RecentActivityKard from '@core/components/RecentActivityKard/RecentActivityKard';
import { CashFlowUnderwritingExperience } from '@clip/CashFlowUnderwritingExperience/kards';
import CreditScore from '@core/components/CreditScore/CreditScore';
import { usePaymentStatus } from '@payments/hooks/usePaymentStatus';
import { OnboardingDashboardQuery } from '@core/graphql/globalTypes';
import { SpendBonus } from '@clip/SpendBonus';
import { IncomeUpdateBanner } from '@clip/IncomeUpdate';
import { GoodStandingTips } from '@clip/GoodStandingTips';
import { useTracking } from '@core/services/TrackService/useTracking';
import { MLFlags } from 'flags';
import ClipOffer from '@clip/ClipProgress/kards/ClipOffer/ClipOffer';
import DynamicAccountSummaryKard from '@core/components/DynamicAccountSummaryKard/DynamicAccountSummaryKard';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';

const ONBOARDING_DASHBOARD_QUERY = gql`
  query OnboardingDashboard($accountId: String!) {
    account(accountId: $accountId) {
      id
      cardDetails {
        last4
        issuanceState
      }
    }
    accountDetails(accountId: $accountId) {
      id
      name
      cardArtUrl
      cardLast4
      securedCardInfo {
        currentAmount {
          value
        }
      }
      cardLockStatus {
        isLocked
      }
    }
  }
`;

const OnboardingDashboard = () => {
  const { isDesktopLarge } = useUserDevice();
  const navigate = useNavigate();
  const { trackClick } = useTracking();
  const { showCreditScore, enableAccountSummaryCardArt } = useFlags<MLFlags>();
  const { data, loading } = useAccountIdQuery<OnboardingDashboardQuery>(
    ONBOARDING_DASHBOARD_QUERY,
    {
      errorPolicy: 'all',
    },
  );

  const {
    refreshEnabled,
    refresh,
    accountInformation,
    loading: acctInfoLoading,
    rtfTreatment,
  } = useContext(AccountInformationContext);

  const customerAndAccountIds = useCustomerAndAccountIdentifiers();

  const {
    isPastDue,
    showPastDueCredit,
    showSettledCurrentBalance,
    hasPastDueBalancePayment,
    isChargedOff,
  } = usePaymentStatus();

  useEffect(() => {
    if (refreshEnabled && !!refresh) {
      refresh({
        refetchInterval: 2000,
        refetches: 5,
      });
    }
  }, [refreshEnabled, refresh]);

  /**
   * When the card is locked, a lock icon button is displayed to
   *  navigate the user to the Card Lock feature
   */
  const onClickLock = () => {
    trackClick({
      name: 'Account Summary Card: Lock icon',
      feature: 'Account Summary',
    });
    navigate('../manage-card/lock-card');
  };

  if (loading || acctInfoLoading) return <LoadingSpinner />;

  const { account, accountDetails } = data || {};
  const { issuanceState } = account?.cardDetails || {};
  const { name, cardLast4, securedCardInfo, cardLockStatus, cardArtUrl } =
    accountDetails || {};
  const { availableCredit, currentBalance } = accountInformation || {};

  const canDisplayDynamicCard = enableAccountSummaryCardArt && cardArtUrl;

  return (
    <div data-testid="DefaultDashboard">
      <IncomeUpdateBanner />
      <div className="pb3">
        <ActivationBannerKard variant={issuanceState} />
      </div>
      <div className="flex">
        <div className={cx(isDesktopLarge ? 'w-50' : 'w-100')}>
          {canDisplayDynamicCard ?
            <DynamicAccountSummaryKard
              cardArtUrl={cardArtUrl}
              last4={cardLast4}
              currentBalance={currentBalance}
              availableCredit={availableCredit}
              cardName={name}
              onUnlock={onClickLock}
              isLocked={Boolean(cardLockStatus?.isLocked)}
              securityDeposit={securedCardInfo?.currentAmount?.value}
              showSettledCurrentBalance={showSettledCurrentBalance}
              showPastDueCredit={showPastDueCredit}
            />
          : <div className="pb3">
              <AccountSummaryCard
                isChargedOff={isChargedOff}
                showSettledCurrentBalance={showSettledCurrentBalance}
                showPastDueCredit={showPastDueCredit}
                headerLevel="H4"
                availableCredit={availableCredit}
                cardName={name}
                cardLast4={cardLast4}
                currentBalance={currentBalance}
                securityDeposit={securedCardInfo?.currentAmount?.value}
                cardAction={{
                  label: 'Account details',
                  linkTo: '../details',
                  tracking: {
                    trackingName: 'Account Summary: Account details link',
                    trackingProperties: customerAndAccountIds,
                  },
                }}
                rtfTreatment={rtfTreatment}
                isPastDue={isPastDue}
                isLocked={Boolean(cardLockStatus?.isLocked)}
                onUnlock={onClickLock}
                hasPastDueBalancePayment={hasPastDueBalancePayment}
              />
            </div>
          }
          {!isDesktopLarge && (
            <>
              <div className="pb3">
                <PaymentKard />
              </div>
              {/** Width of dynamic card art */}
              <OpportunityTileCarousel />
            </>
          )}
          <div className="pb3">
            <RecentActivityKard />
            <CashFlowUnderwritingExperience />
          </div>
          {!isDesktopLarge && (
            <>
              <SpendBonus />
              <ClipOffer />
              <GoodStandingTips />
              {showCreditScore && <CreditScore />}
            </>
          )}
        </div>
        {isDesktopLarge && (
          <div className="w-50 pl3">
            <div className="pb3">
              <PaymentKard />
            </div>
            <div>
              <OpportunityTileCarousel />
              <SpendBonus />
              <ClipOffer />
            </div>
            <div>
              <GoodStandingTips />
            </div>
            {showCreditScore && <CreditScore />}
          </div>
        )}
      </div>
    </div>
  );
};

export default OnboardingDashboard;
